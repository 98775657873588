import useAxios from "../../core/jwt/useAxios";
import { EMPLOYEE } from "../../core/service/api";
import {
  getEmployeeUpcomingProgramsFailure,
  getEmployeeUpcomingProgramsStart,
  getEmployeeUpcomingProgramsSuccess,
  getEmployerAdminDetailsForRegistrationCompleteFailure,
  getEmployerAdminDetailsForRegistrationCompleteStart,
  getEmployerAdminDetailsForRegistrationCompleteSuccess,
  getUpcomingSubscribedProgramsFailure,
  getUpcomingSubscribedProgramsStart,
  getUpcomingSubscribedProgramsSuccess,
} from "./EmployeeActions";

export const getEmployeeUpcomingPrograms = async (dispatch) => {
  dispatch(getEmployeeUpcomingProgramsStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .get(EMPLOYEE.GET_EMPLOYEE_UP_COMING_PROGRAMMES)
      .then((response) => {
        resolve(response);
        dispatch(getEmployeeUpcomingProgramsSuccess(response));
      })
      .catch((err) => {
        reject(err);
        dispatch(getEmployeeUpcomingProgramsFailure(err));
      });
  });
};

export const getUpcomingSubscribedPrograms = async (dispatch) => {
  dispatch(getUpcomingSubscribedProgramsStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .get(EMPLOYEE.GET_UP_COMING_SUBSCRIBED_PROGRAMMES)
      .then((response) => {
        resolve(response);
        dispatch(getUpcomingSubscribedProgramsSuccess(response));
      })
      .catch((err) => {
        reject(err);
        dispatch(getUpcomingSubscribedProgramsFailure(err));
      });
  });
};

export const getEmployerAdminDetailsForRegistrationComplete = async (
  userId,
  dispatch
) => {
  dispatch(getEmployerAdminDetailsForRegistrationCompleteStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .get(
        EMPLOYEE.GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE(userId)
      )
      .then((response) => {
        resolve(response);
        dispatch(getEmployerAdminDetailsForRegistrationCompleteSuccess(response.data));
      })
      .catch((err) => {
        reject(err);
        dispatch(getEmployerAdminDetailsForRegistrationCompleteFailure(err));
      });
  });
};
