import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Getzentable from "../../components/GetZen/Table/getzentable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faStar,
  faPaste as faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
// import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { MainContext } from "../../context/MainContext";
import {
  getEmployeeUpcomingPrograms,
  getUpcomingSubscribedPrograms,
} from "../../context/EmployeeContext/apiCalls";
import moment from "moment";
import Tippy from "@tippyjs/react";

function Index() {
  const navigate = useNavigate();
  const { userData, dispatch } = useContext(MainContext);
  const [employeeData, setEmployeeData] = useState([]);
  const [employerData, setEmployerData] = useState([]);

  useEffect(() => {
    let isApiSubscribed = true;

    if (userData === "EMPL") {
      getEmployeeUpcomingPrograms(dispatch).then((response) => {
        if (isApiSubscribed) {
          setEmployeeData(response.data);
        }
      });
    }

    if (userData === "CADM") {
      getUpcomingSubscribedPrograms(dispatch).then((response) => {
        if (isApiSubscribed) {
          setEmployerData(response.data);
        }
      });
    }

    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [userData, dispatch]);

  const ScheduledPro = [
    {
      name: "Programme Name",
      selector: (row) => {
        return (
          <Tippy
            interactive={true}
            content={row.programeeName}
            animation={"shift-toward"}
          >
            <div>{row.programeeName}</div>
          </Tippy>
        );
      },
      sortable: true,
    },
    {
      name: "Date / Time",
      selector: (row) => {
        return (
          <Tippy
            interactive={true}
            content={moment
              .utc(row.startDate)
              .local()
              .format("YYYY-MM-DD hh:mm a")}
            animation={"shift-toward"}
          >
            <div>
              {moment.utc(row.startDate).local().format("YYYY-MM-DD hh:mm a")}
            </div>
          </Tippy>
        );
      },
      sortable: true,
    },
    {
      name: "	Practitioner",
      selector: (row) => {
        return (
          <Tippy
            interactive={true}
            content={row.practitioner}
            animation={"shift-toward"}
          >
            <div>{row.practitioner}</div>
          </Tippy>
        );
      },
      sortable: true,
    },
    {
      name: "	Booking Count",
      width: "175px",
      selector: (row) => {
        return (
          <Tippy
            interactive={true}
            content={row.bookingCount}
            animation={"shift-toward"}
          >
            <div>{row.bookingCount}</div>
          </Tippy>
        );
      },
      sortable: true,
      omit: userData === "EMPL" ? true : false,
    },
    {
      name: "	PO Number",
      width: "150px",
      selector: (row) => {
        return (
          <Tippy
            interactive={true}
            content={row.poNumber}
            animation={"shift-toward"}
          >
            <div>{row.poNumber}</div>
          </Tippy>
        );
      },
      sortable: true,
      omit: userData === "EMPL" ? true : false,
    },
    {
      name: "Programme Meeting Link",
      width: "250px",
      selector: (row) => {
        return row.meetingLink === "" ? null : (
          <div>
            <button
              className="btn btn-sm btn-outline me-1"
              data-toggle="tooltip"
              title="Meeting Link"
              onClick={() => copyClipBoard(row)}
            >
              <FontAwesomeIcon icon={faClipboardCheck} className="me-1" />
              <span>Copy to clipboard</span>
            </button>
            {/* <button className="btn btn-sm btn-outline">view</button> */}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "",
      width: "100px",
      selector: (row) => {
        return (
          <div>
            <button
              className="btn btn-sm btn-outline"
              onClick={() => viewBtnClicked(row)}
            >
              view
            </button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const copyClipBoard = (data) => {
    var link = data.meetingLink;

    navigator.clipboard.writeText(link);
  };

  const viewBtnClicked = (data) => {
    if (userData === "CADM") {
      navigate("/our-programme/" + data.programmeId);
    }
    if (userData === "EMPL") {
      navigate("/book-programme/" + data.programmeBookingId);
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        {userData === "EMPL" ? (
          <div className="row mt-4 mb-3 px-2 pb-3">
            <div className="col-12 pt-3 mb-3">
              <h3>Upcoming Programmes</h3>
            </div>
            <div className="col-12 tb-design-1">
              <Getzentable data={employeeData} columns={ScheduledPro} />
              {/* <Getzentable data={employeeData} columns={completePro} /> */}
            </div>
          </div>
        ) : null}
        {userData === "CADM" ? (
          <div className="row my-4 mb-3 px-2 pb-3">
            <div className="col-12 pt-3 mb-3">
              {/* <hr /> */}
              <h3>Scheduled Programmes</h3>
            </div>
            <div className="col-12 tb-design-1">
              <Getzentable data={employerData} columns={ScheduledPro} />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Index;
