import React from 'react'
import { NavLink } from 'react-router-dom'

import logo from '../../images/png/getzen.png'

export default function Header() {
    return (
        <div className='container mt-3'>
            <div className='row'>
                <NavLink className='navbar-brand mb-3' to="/dashboard">
                    <img src={logo} alt="Navigate" className="img-fluid p-2" />
                </NavLink>
                <hr></hr>
            </div>
        </div>
    )
}