import useAxios from "../../core/jwt/useAxios";
import { SHAREDDATA } from "../../core/service/api";
import {
  getSharedDataFailure,
  getSharedDataStart,
  getSharedDataSuccess,
} from "./SharedDataActions";

export const getSharedData = async (dispatch) => {
  dispatch(getSharedDataStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .get(SHAREDDATA.GET_SHAREDDATA)
      .then((response) => {
        dispatch(getSharedDataSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(getSharedDataFailure(err && err.response.data));
        reject(err);
      });
  });
};
