import React from "react";
import { useState } from "react";

const Star = ({ starId, marked }) => {
  return (
    <span
      className="star-5"
      star-id={starId}
      role="button"
      style={{ color: "#b68e63", cursor: "pointer" }}
    >
      {marked ? "\u2605" : "\u2606"}
    </span>
  );
};

// Create an array of 5: Array.from({length: 5}, (v,i) => i)
const StarRating = ({ name, sendDataBack }) => {
  // Manages on Hover selection of a star
  const [selection, setSelection] = useState(0);

  // Manages rating selection
  const [rating, setRating] = useState(0);

  const hoverOver = (event) => {
    let starId = 0;
    if (event && event.target && event.target.getAttribute("star-id")) {
      starId = event.target.getAttribute("star-id");
    }
    setSelection(starId);
  };

  const selectStart = (event) => {
    setRating(event.target.getAttribute("star-id"));
    sendDataBack({ [name]: event.target.getAttribute("star-id") });
  };

  return (
    <div
      onMouseOver={hoverOver}
      onMouseOut={() => hoverOver(null)}
      onClick={(e) => selectStart(e)}
    >
      {Array.from({ length: 5 }, (v, i) => (
        <Star
          key={i}
          starId={i + 1}
          marked={selection ? selection > i : rating > i}
        />
      ))}
    </div>
  );
};

export default StarRating;
