import useAxios from "../../core/jwt/useAxios";
import { IDENTITYSERVICE } from "../../core/service/api";
import {
  UpdateEmployerAdminDetailsFailure,
  UpdateEmployerAdminDetailsStart,
  UpdateEmployerAdminDetailsSuccess,
  adminResetPasswordFailure,
  adminResetPasswordStart,
  adminResetPasswordSuccess,
  getUserProfilePictureFailure,
  getUserProfilePictureStart,
  getUserProfilePictureSuccess,
  registerEmployeeFailure,
  registerEmployeeStart,
  registerEmployeeSuccess,
  resetPasswordFailure,
  resetPasswordStart,
  restePasswordSuccess,
} from "./IdentityServiceActions";

export const registerEmployee = async (data, dispatch) => {
  dispatch(registerEmployeeStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(IDENTITYSERVICE.REGISTER, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch(registerEmployeeSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(registerEmployeeFailure(err.response.data));
        reject(err);
      });
  });
};

export const resetPassword = async (data, dispatch) => {
  dispatch(resetPasswordStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(IDENTITYSERVICE.RESET_PASSWORD, data)
      .then((response) => {
        dispatch(restePasswordSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(resetPasswordFailure());
        reject(err);
      });
  });
};

export const getUserProfilePicture = async (dispatch) => {
  dispatch(getUserProfilePictureStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(IDENTITYSERVICE.GET_USER_PROFILE_PICTURE)
      .then((response) => {
        dispatch(getUserProfilePictureSuccess(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(getUserProfilePictureFailure(error));
        reject(error);
      });
  });
};

export const adminRestPasswordLink = async (data, dispatch) => {
  dispatch(adminResetPasswordStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(IDENTITYSERVICE.SEND_RESET_PASSWORD_LINK, data)
      .then((response) => {
        dispatch(adminResetPasswordSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(adminResetPasswordFailure(err));
        reject(err);
      });
  });
};

export const UpdateEmployerAdminDetails = async (data, dispatch) => {
  dispatch(UpdateEmployerAdminDetailsStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(IDENTITYSERVICE.UPDATE_EMPLOYER_ADMIN_DETAILS, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch(UpdateEmployerAdminDetailsSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(UpdateEmployerAdminDetailsFailure(err.response.data));
        reject(err);
      });
  });
};
