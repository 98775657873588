const EMPLOYEE_INITIAL_FETCHING_VALUES = {
  isGetEmployeeUpcomingProgramsFetching: false,
  isGetUpcomingSubscribedProgramsFetching: false,
  isGetEmployerAdminDetailsForRegistrationCompleteFetching : false
};

export const EMPLOYEE_INITIAL_VALUES = {
  employerAdminDetailsForRegistration : null,
  error: false,
  employeeFetchingState: {
    ...EMPLOYEE_INITIAL_FETCHING_VALUES,
  },
};
