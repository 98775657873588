import { useState, useContext, useRef, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { MainContext } from "../../context/MainContext";
import { logout } from "../../context/authContext/AuthActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";

import logo from "../../images/png/getzen.png";
import { getUserProfilePicture } from "../../context/IdentityServiceContext/apiCalls";
const Navigation = () => {
  const [dpShow, setDpShow] = useState(false);
  const { userDetails, userData, dispatch } = useContext(MainContext);
  const wrapperRef = useRef(null);
  const boxref = useRef(null);
  useOutsideAlerter(wrapperRef, boxref);
  const [proImg, setProImg] = useState();
  const Navigate = useNavigate();
  const navBarRef = useRef(null);
  const [mobileNavToggle, setMobileNavToggle] = useState(false);

  useEffect(() => {
    let isApiSubscribed = true;

    getUserProfilePicture(dispatch).then((response) => {
      if (isApiSubscribed) {
        setProImg(response.data.profileImageUrl);
      }
    });
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [dispatch]);

  function useOutsideAlerter(ref, boxe) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          boxe.current &&
          !boxe.current.contains(event.target)
        ) {
          setDpShow(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, boxe]);
  }

  function showDpDown() {
    setDpShow((current) => !current);
  }

  const logOutClick = () => {
    Navigate("/", { state: { prevUrl: null } });
    dispatch(logout());
    setTimeout(() => {
      localStorage.removeItem("hashCode");
    }, 100);
  };

  const toggleNavBar = () => {
    setMobileNavToggle((current) => !current);
    if (!mobileNavToggle) {
      navBarRef.current.style.display = "block";
    } else {
      navBarRef.current.style.display = "none";
    }
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
        <div className="container-fluid">
          <div className="navbar-brand">
            <NavLink
              className={({ isActive }) => (isActive ? "active" : "")}
              to="/dashboard"
            >
              <img src={logo} alt="Navigate" className="img-fluid p-2" />
            </NavLink>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavBar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            ref={navBarRef}
            className="collapse navbar-collapse"
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {/* <li className="nav-item px-2">
                <NavLink className="nav-link fw-bold" to="/dashboard">
                  Dashboard
                </NavLink>
              </li> */}
              {userData !== "EMPL" ? (
                <li>
                  <NavLink className="nav-link fw-bold" to="/our-programme">
                    Booking
                  </NavLink>
                </li>
              ) : null}
              <li>
                <NavLink className="nav-link fw-bold" to="/mybooking">
                  My Bookings
                </NavLink>
              </li>
            </ul>
            <div className="d-flex">
              <div
                ref={boxref}
                className="btn-group ms-5 me-3 user-icon-panel"
                onClick={showDpDown}
              >
                <span
                  className="dropdown-toggle spn-c-p"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {proImg ? (
                    <img
                      src={proImg}
                      alt="..."
                      className="rounded-circle profile border border-3"
                    />
                  ) : null}
                  {userDetails ? (
                    <span className="mx-2 name-topbar">
                      {userDetails.firstName} {userDetails.lastName}
                    </span>
                  ) : null}
                </span>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li>
                    <button className="dropdown-item" type="button">
                      <i className="fa fa-cog" aria-hidden="true"></i> Settings
                    </button>
                  </li>
                  <li>
                    <button className="dropdown-item" type="button">
                      <i className="fa fa-sign-out" aria-hidden="true"></i>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
              <form></form>
            </div>
          </div>
        </div>
      </nav>
      {dpShow ? (
        <div
          className="dp-down-n dropdown-menu dropdown-menu-end show"
          ref={wrapperRef}
        >
          <li>
            <button
              className="dropdown-item logout-btn"
              type="button"
              onClick={() => logOutClick()}
            >
              <FontAwesomeIcon icon={faSignOut} className="me-1" />
              Logout
            </button>
          </li>
        </div>
      ) : null}
    </>
  );
};
export default Navigation;
