import useAxios from "../../core/jwt/useAxios";
import { PRACTITIONER } from "../../core/service/api";
import {
  getPractitionerDetailsFailure,
  getPractitionerDetailsStart,
  getPractitionerDetailsSuccess,
  getPractitionerListForClientPortalProgrammeFailure,
  getPractitionerListForClientPortalProgrammeStart,
  getPractitionerListForClientPortalProgrammeSuccess,
} from "./PractitionerAction";

export const getPractitionerListForClientPortalProgramme = async (
  data,
  dispatch
) => {
  dispatch(getPractitionerListForClientPortalProgrammeStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(
        PRACTITIONER.GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME,
        data
      )
      .then((response) => {
        dispatch(
          getPractitionerListForClientPortalProgrammeSuccess(response.data)
        );
        resolve(response);
      })
      .catch((error) => {
        dispatch(getPractitionerListForClientPortalProgrammeFailure(error));
        reject(error);
      });
  });
};

export const getPractitionerDetails = async (data, dispatch) => {
  dispatch(getPractitionerDetailsStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PRACTITIONER.GET_PRACTITIONER_DETAILS, data)
      .then((response) => {
        dispatch(getPractitionerDetailsSuccess(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(getPractitionerDetailsFailure(error));
        reject(error);
      });
  });
};
