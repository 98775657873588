export const getCompanyDetailStart = () => ({
  type: "GET_COMPANY_DETAIL_START",
});

export const getCompanyDetailSuccess = () => ({
  type: "GET_COMPANY_DETAIL_SUCCESS",
});

export const getCompanyDetailFailure = () => ({
  type: "GET_COMPANY_DETAIL_FAILURE",
});
