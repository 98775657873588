const companyUrl = process.env.REACT_APP_API_URL_COMPANY;
const sharedDataUrl = process.env.REACT_APP_API_URL_SHARED_DATA;
const employeeUrl = process.env.REACT_APP_API_EMPLOYEE;
const identityServiceUrl = process.env.REACT_APP_API_IDENTITY_SERVICE;
const authUrl = process.env.REACT_APP_API_IDENTITY_SERVICE;
const programmeUrl = process.env.REACT_APP_API_PROGRAMME;
const practitionerUrl = process.env.REACT_APP_API_PRACTITIONER;

export const AUTH = {
  LOGIN: `${authUrl}/api/User/client-login`, // POST METHOD
};

export const COMPANY = {
  GET_DETAILS_FOR_EMPLOYEE_REGISTRATION: (id) =>
    `${companyUrl}/api/company/companyDetailsForEmployeeRegistration/${id}`, //GET METHOD
};

export const SHAREDDATA = {
  GET_SHAREDDATA: `${sharedDataUrl}/api/SharedData/GetAll`, // GET METHOD
};

export const EMPLOYEE = {
  GET_EMPLOYEE_UP_COMING_PROGRAMMES: `${employeeUrl}/api/ClientEmployee/GetEmployeeUpcomingPrograms`, // GET METHOD
  GET_UP_COMING_SUBSCRIBED_PROGRAMMES: `${employeeUrl}/api/ClientEmployee/GetUpcomingSubscribedPrograms`, // GET METHOD
  GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE: (id) =>
    `${employeeUrl}/api/ClientEmployee/GetEmployerAdminDetailsForRegistrationComplete?id=${id}`, // GET METHOD
};

export const IDENTITYSERVICE = {
  REFRESH: `${identityServiceUrl}/api/Token/refresh`, // POST METHOD
  REGISTER: `${identityServiceUrl}/api/User/register`, // POST METHOD
  SEND_RESET_PASSWORD_LINK: `${identityServiceUrl}/api/User/GenerateResetPasswordLink`,
  RESET_PASSWORD: `${identityServiceUrl}/api/User/ResetPassword`, //POST METHOD
  GET_USER_PROFILE_PICTURE: `${identityServiceUrl}/api/User/GetUserProfilePicture`, // POST METHOD
  UPDATE_EMPLOYER_ADMIN_DETAILS: `${identityServiceUrl}/api/User/UpdateEmployerAdminDetails`, // POST METHOD
};

export const PROGRAMME = {
  GET_CLIENT_PROGRAMME: `${programmeUrl}/api/ClientProgramme/GetAllProgrammes`, // POST METHOD
  SHAREDDATA_FOR_PROGRAMMES: `${programmeUrl}/api/ClientProgramme/GetSharedDataForProgrammes`, // GET METHOD
  SUBSCRIBE_TO_PROGRAMME: `${programmeUrl}/api/ClientProgramme/SubscribeToProgramme`, // POST METHOD
  GET_PROGRAMME_DETAILES_FOR_CLIENT_PORTAL: `${programmeUrl}/api/ClientProgramme/GetProgrammeDetailsForClientPortal`, // POST METHOD
  GET_TIME_INTERVALS: `${programmeUrl}/api/ClientProgramme/GetTimeIntervals`, // POST METHOD
  GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL: `${programmeUrl}/api/ClientProgramme/GetProgrammeBookingDetailsForClientPortal`, // POST METHOD
  BOOK_SLOT: `${programmeUrl}/api/ClientProgramme/BookSlot`, // POST METHOD
  RATE_EMPLOYEE_PROGRAMME: `${programmeUrl}/api/ClientProgramme/RateEmployeeProgramme`, // POST METHOD
  GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING: `${programmeUrl}/api/ClientProgramme/GetEmployeeProgrammeDetailsforRating`, // POST METHOD
};

export const PRACTITIONER = {
  GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME: `${practitionerUrl}/api/ClientPractitioner/GetPractitionerListForClientPortalProgramme`, // POST METHOD
  GET_PRACTITIONER_DETAILS: `${practitionerUrl}/api/ClientPractitioner/GetPractitionerDetails`, // POST METHOD
};
