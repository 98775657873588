const PRACTIIONER_INITIAL_FETCHING_VALUES = {
  isGetPractitionerListForClientPortalProgrammeFetching: false,
  isGetPractitionerDetailsFetching: false,
};

export const PRACTITIONER_INITIAL_VALUES = {
  error: false,
  practitionerFetchingState: {
    ...PRACTIIONER_INITIAL_FETCHING_VALUES,
  },
};
