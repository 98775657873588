import React, { useState, useEffect } from "react";

export default function Index({ message: propMessage }) {
  const [show, setShow] = useState(true);
  const [message, setMessage] = useState([]);

  useEffect(() => {
    let isApiSubscribed = true;

    if (!show) {
      setMessage([]);
    }

    if (isApiSubscribed) {
      setMessage(propMessage);
    }
    return () => {
      isApiSubscribed = false;
      setMessage([]);
    };
  }, [propMessage, show]);

  setTimeout(() => {
    setShow(false);
  }, 3000);

  return (
    <>
      {show ? (
        message[0] !== undefined && message[0].status !== 401 ? (
          message[0] !== undefined ? (
            <div className="info-alert_box ">
              {message.map((item, index) => (
                <div key={index} className="alert alert-danger">
                  <strong>Error! </strong>
                  {item.message !== undefined && item.message !== null ? (
                    <span>{item.message}</span>
                  ) : (
                    <span>Error occurred</span>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="info-alert_box ">
              <div className="alert alert-danger">
                <span>
                  <strong>Error! </strong> Error occurred
                </span>
              </div>
            </div>
          )
        ) : (
          <div className="info-alert_box ">
            <div className="alert alert-danger">
              <span>
                <strong>Error! </strong> Login Failed.
              </span>
            </div>
          </div>
        )
      ) : null}
    </>
  );
}
