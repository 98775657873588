const AUTH_INITIAL_FETCHING_VALUES = {
  isAuthLoginFetching: false,
};

export const AUTH_INITIAL_VALUES = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  userData: JSON.parse(localStorage.getItem("userDetails"))?.userType || null,
  userDetails: JSON.parse(localStorage.getItem("userDetails")) || null,
  error: false,
  authFetchingState: {
    ...AUTH_INITIAL_FETCHING_VALUES,
  },
};
