export const MapFunc = (array, label, value) => {
  return array.map((i) => ({ label: i[label], value: i[value] }));
};

export const DpObjectHandle = (value, array) => {
  if (array.find((item) => item.value === value)) {
    if (array.length !== 0) {
      return {
        value: value ? value : "",
        label: value ? array.find((item) => item.value === value).label : "",
      };
    }
  } else {
    return null;
  }
};

export const DpObjectLabelHandle = (label, array) => {
  if (array.find((item) => item.label === label)) {
    if (array.length !== 0) {
      return {
        value: label ? array.find((item) => item.label === label).value : "",
        label: label ? label : "",
      };
    }
  }
};
