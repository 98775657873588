const CompanyReducer = (state, action) => {
  switch (action.type) {
    case "GET_COMPANY_DETAIL_START":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isGetCompanyDetailesFetching: true,
        },
        error: false,
      };
    case "GET_COMPANY_DETAIL_SUCCESS":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isGetCompanyDetailesFetching: false,
        },
        error: false,
      };
    case "GET_COMPANY_DETAIL_FAILURE":
      return {
        ...state,
        companyFetchingState: {
          ...state.companyFetchingState,
          isGetCompanyDetailesFetching: false,
        },
        error: true,
      };
    default:
      return { ...state };
  }
};

export default CompanyReducer;
