import React, { useRef, useState } from "react";
import logo from "../../images/svg/logo.svg";
import Alert from "../../components/GetZen/Alert";

import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/GetZen/Input";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MainContext } from "../../context/MainContext";
import { useContext } from "react";
import GlobalError from "../../components/GetZen/GlobalError";
import { authLogin } from "../../context/authContext/apiCalls";
import { adminRestPasswordLink } from "../../context/IdentityServiceContext/apiCalls";

const CustomSignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dispatch, error } = useContext(MainContext);
  const userName = useRef(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [globaleError, setGlobalError] = useState([]);
  const [alertType, setAlertType] = useState("");

  const initialValue = {
    username: "",
    password: "",
  };

  /* eslint-disable */
  // Yup Validation
  let validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("User email is required!")
      .email("Please enter valid email!"),
    password: Yup.string().required("Password is required!"),
  });

  function signIn(data) {
    authLogin(data, dispatch)
      .then(() => {
        if (location.state.prevUrl) {
          setTimeout(() => {
            navigate(location.state.prevUrl);
          }, 10);
        } else {
          navigate("/our-programme");
        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          setGlobalError([err.response.data]);
        }
      });
  }

  function forogotPassword() {
    if (userName.current.value === "") {
    } else {
      const data = {
        email: userName.current.value,
      };
      adminRestPasswordLink(data, dispatch)
        .then(() => {
          setAlertMessage("Password Reset Link Send Successfully");
          setAlertDisplay(true);
          setAlertType("Success");
          setTimeout(() => {
            setAlertDisplay(false);
          }, 3000);
        })
        .catch((err) => {
          if (err.response !== undefined) {
            setGlobalError([err.response.data]);
          }
        });
    }
  }

  return (
    <>
      <div className="container">
        <div>
          <div className="row bg-white shadow-sm card-box text-center">
            <div>
              <div>
                <img src={logo} alt="Navigate" width={96} height={74} />
              </div>

              <Formik
                initialValues={initialValue}
                validationSchema={validationSchema}
                onSubmit={signIn}
              >
                {(formik) => {
                  return (
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-1 text-start">
                        <label
                          htmlFor="username"
                          className="form-label fw-medium-bold"
                        >
                          User Email
                        </label>
                        <Input
                          refVal={userName}
                          type="text"
                          className="form-control form-control-sm"
                          id="username"
                          name="username"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                        />
                        <ErrorMessage
                          name="username"
                          component="span"
                          className="error"
                        />
                      </div>

                      <div className="mb-1 text-start">
                        <label
                          htmlFor="password"
                          className="form-label fw-medium-bold"
                        >
                          Password
                        </label>
                        <Input
                          type="password"
                          className="form-control form-control-sm"
                          id="password"
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                        />
                        <ErrorMessage
                          name="password"
                          component="span"
                          className="error"
                        />
                      </div>

                      <div className="text-end">
                        <span
                          className="text-dark spn-c-p forgot-password"
                          onClick={() => {
                            formik.validateField("username"),
                              formik.setFieldTouched("username"),
                              forogotPassword();
                          }}
                        >
                          Forgot password?
                        </span>
                      </div>
                      <div className="button-login">
                        <button
                          type="submit"
                          className="btn btn-getzenprimary btn-sm"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Alert
        message={alertMessage}
        display={alertDisplay}
        statusType={alertType}
      />
      {error ? <GlobalError message={globaleError} /> : null}
    </>
  );
};
export default CustomSignIn;
