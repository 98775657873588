import React, { useContext } from "react";
import Header from "../../components/Header2/Header";
import Input from "../../components/GetZen/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../context/IdentityServiceContext/apiCalls";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Alert from "../../components/GetZen/Alert";
import { MainContext } from "../../context/MainContext";

export default function PasswordChange() {
  const [searchParams] = useSearchParams();
  const tokenId = searchParams.get("id");
  const emailName = searchParams.get("email");

  const { dispatch } = useContext(MainContext);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDisplay, setAlertDisplay] = useState(false);
  const Navigate = useNavigate();
  const [alertType, setAlertType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorDisplay, setIsErrorDisplay] = useState(false);

  const initialValue = {
    password: "",
    confirmpassword: "",
  };

  /* eslint-disable */
  // Yup Validation
  let validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .required("Password is required!")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(/^(?=.*[0-9])/, "Must Contain One Digit Character")
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      ),
    confirmpassword: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .oneOf([Yup.ref("password"), null], "Passwords must match!")
      .required("Confrim password is required!"),
  });

  function submit(data, { resetForm }) {
    const dataPassword = {
      password: data.password,
      email: emailName,
      token: tokenId,
    };

    resetPassword(dataPassword, dispatch)
      .then(() => {
        setAlertMessage("Password Reset Success!");
        setAlertDisplay(true);
        setAlertType("Success");
        setTimeout(() => {
          setAlertDisplay(false);
          Navigate("/");
        }, 3000);
        resetForm();
      })
      .catch((err) => {
        setIsErrorDisplay(true);
        setErrorMessage(
          "Password Reset Failed! Change Password Link is Expired. Please regenerate the link via forgot password option."
        );
        setTimeout(() => {
          setIsErrorDisplay(false);
        }, 5000);
      });
  }

  const goBack = () => {
    Navigate("/");
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row mb-3">
          <h3>Change Password</h3>
          <Formik
            initialValues={initialValue}
            validationSchema={validationSchema}
            onSubmit={submit}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit} onReset={props.handleReset}>
                <div className="row mt-3 px-2 pb-3">
                  <div className="col-sm-6 bg-white shadow-sm">
                    <div className="mb-2">
                      <label htmlFor="password" className="form-label f-16">
                        New password
                      </label>
                      <Input
                        type="password"
                        name="password"
                        className="form-control form-control-sm"
                        id="password"
                        value={props.values.password}
                        onChange={props.handleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="confirmpassword"
                        className="form-label f-16"
                      >
                        Confirm password
                      </label>
                      <Input
                        type="password"
                        name="confirmpassword"
                        className="form-control form-control-sm"
                        id="confirmpassword"
                        value={props.values.confirmpassword}
                        onChange={props.handleChange}
                      />
                      <ErrorMessage
                        name="confirmpassword"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="mb-3 text-end mt-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-outline mx-2"
                        onClick={() => goBack()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-getzenprimary px-3 btn-sm"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <Alert
          message={alertMessage}
          display={alertDisplay}
          statusType={alertType}
        />
        {isErrorDisplay ? (
          <div className="info-alert_box ">
            <div className="alert alert-danger">
              <strong>Error! </strong>
              <span>{errorMessage}</span>
            </div>
          </div>
        ) : null}
        ;
      </div>
    </>
  );
}
