import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import StarRating from "../../components/GetZen/StarRating";
import Header from "../../components/Header2/Header";
import { MainContext } from "../../context/MainContext";
import {
  getEmployeeProgrammeDetailsforRating,
  rateEmployeeProgramme,
} from "../../context/programmeContext/apiCalls";
import GlobalError from "../../components/GetZen/GlobalError";
import moment from "moment";
import Alert from "../../components/GetZen/Alert";
import logo from "../../images/svg/logo.svg";

const Rate = () => {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");
  const bid = searchParams.get("bid");
  const { error, dispatch } = useContext(MainContext);
  const [rating, setRating] = useState({
    programmeRating: 0,
    practitionerRating: 0,
  });
  const [rateErrorMsg, setRateErrorMsg] = useState("");
  function getDataBack(e) {
    setRating((g) => ({ ...g, ...e }));
  }
  const [globaleError, setGlobalError] = useState([]);
  const [programmeDetails, setProgrammeDetails] = useState({});
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  setTimeout(() => {
    if (alertDisplay === true) {
      setAlertDisplay(false);
    }
  }, 3000);

  useEffect(() => {
    let isApiSubscribed = true;
    var data = {
      programmeBookingId: bid,
    };
    getEmployeeProgrammeDetailsforRating(data, dispatch)
      .then((response) => {
        if (isApiSubscribed) {
          setProgrammeDetails(response.data);
        }
      })
      .catch((err) => {
        setGlobalError((g) => [...g, err.response.data]);
      });

    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [bid, dispatch]);

  const submitRate = () => {
    if (rating.programmeRating && rating.practitionerRating) {
      setRateErrorMsg("");
      var data = {
        programmeBookingId: bid,
        userId: uid,
        practitionerRatingValue: rating.practitionerRating,
        programmeRatingValue: rating.programmeRating,
      };
      rateEmployeeProgramme(data, dispatch)
        .then(() => {
          setAlertMessage("You are successfully rated the programme");
          setAlertDisplay(true);
          setAlertType("Success");
        })
        .catch((err) => {
          setGlobalError([err.response.data]);
        });
    } else if (!rating.practitionerRating && !rating.programmeRating) {
      setRateErrorMsg("Please rate for programme and practitioner");
    } else if (!rating.programmeRating) {
      setRateErrorMsg("Please rate for programme");
    } else if (!rating.practitionerRating) {
      setRateErrorMsg("Please rate for practitioner");
    }
  };

  return (
    <>
      <div className="container">
        <div>
          <div className="row bg-white shadow-sm card-box text-center rating-box">
            <div>
              <div className="mt-2">
                <img src={logo} alt="Navigate" width={96} height={74} />
              </div>

              <div className="row mt-5 align-items-start justify-content-start">
                <div className="col ">
                  <h3 className="rating-heading rating-font">Programme Name</h3>
                </div>
                <div className="col rating-font">
                  <div className="">
                    {programmeDetails.programmeName
                      ? programmeDetails.programmeName
                      : null}
                  </div>
                </div>
              </div>

              <div className="row mt-2 align-items-start justify-content-start">
                <div className="col">
                  <h3 className="rating-heading rating-font">
                    Practitioner Name
                  </h3>
                </div>
                <div className="col rating-font">
                  <div className="text-capitalize">
                    {programmeDetails.practitionerName
                      ? programmeDetails.practitionerName
                      : null}
                  </div>
                </div>
              </div>

              <div className="row mt-2 align-items-start justify-content-start">
                <div className="col">
                  <h3 className="rating-heading rating-font">Programme Date</h3>
                </div>
                <div className="col rating-font">
                  <div className="text-capitalize">
                    {moment
                      .utc(programmeDetails.programmeDateTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm A")}
                  </div>
                </div>
              </div>

              <div className="row mt-2 align-items-center justify-content-start">
                <div className="col ">
                  {" "}
                  <h3 className="rating-heading rating-font ">
                    Rate Programme <span style={{ color: "red" }}>*</span>
                  </h3>
                </div>
                <div className="col d-flex justify-content-start">
                  <StarRating
                    name="programmeRating"
                    sendDataBack={getDataBack}
                  />
                </div>
              </div>

              <div className="row mt-2 align-items-center justify-content-start">
                <div className="col rating-heading rating-font">
                  <h3 className="rating-heading">
                    Rate Practitioner <span style={{ color: "red" }}>*</span>
                  </h3>
                </div>
                <div className="col d-flex justify-content-start">
                  <StarRating
                    name="practitionerRating"
                    sendDataBack={getDataBack}
                  />
                </div>
              </div>
              {rateErrorMsg !== "" ? (
                <span className="error">{rateErrorMsg}</span>
              ) : null}
              <div className="d-flex justify-content-center mb-4 mt-3">
                <button
                  type="button"
                  className="btn btn-getzenprimary btn-sm w-100"
                  onClick={() => submitRate()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Alert
        message={alertMessage}
        display={alertDisplay}
        statusType={alertType}
      />
      {error ? <GlobalError message={globaleError} /> : null}
    </>
  );
};

export default Rate;
