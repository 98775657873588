import React from 'react';
import PropTypes from 'prop-types';

function Button({ children, className, onClick, type, dataTip }) {
  return (
    <button type={type} onClick={onClick} className={className} data-tip={dataTip}>{children}</button>
  );
}

Button.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  dataTip: PropTypes.string,
};

export default Button;