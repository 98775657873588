import React from "react";
import Select from "react-select";

class CustomSelect extends React.Component {
  selectRef = null;

  handleChange = (value) => {
    // this is going to call setFieldValue and manually update values.topcis
    if (this.props.onChange !== undefined) {
      this.props.onChange(this.props.name, value);
    }
  };

  onClear = () => {
    this.selectRef.clearValue();
  };

  render() {
    return (
      <div>
        <Select
          ref={(ref) => {
            this.selectRef = ref;
          }}
          name={this.props.name}
          options={this.props.options}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={this.props.value}
          isSearchable={this.props.isSearchable}
          isMulti={this.props.isMulti}
          isClearable={this.props.isClearable}
        />
      </div>
    );
  }
}

export default CustomSelect;
