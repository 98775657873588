import { Navigate, useLocation } from "react-router-dom";
import GetPrevUrl from "./GetPrevUrl";

function EmployeeProtectRoute({ children, user, roleType }) {
  const location = useLocation();

  GetPrevUrl();

  return user && roleType !== "EMPL" ? (
    children
  ) : (
    <Navigate
      to="/login"
      state={{ prevUrl: location.pathname }}
      replace={true}
    />
  );
}

export default EmployeeProtectRoute;
