import React, { useEffect, useState } from "react";

export default function Index({ message, display, statusType }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(display);

    return () => {
      setShow(false);
    };
  }, [display]);

  setTimeout(() => {
    if (show === true) {
      setShow(false);
    }
  }, 3000);
  return (
    <>
      {show ? (
        <div className="info-alert_box alert alert-success">
          <strong>
            {statusType} {statusType ? "!" : null}
          </strong>{" "}
          {message}
        </div>
      ) : null}
    </>
  );
}
