import axios from "axios";
import { IDENTITYSERVICE } from "../service/api";

// const useAxios = () => {
let authTokens = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const axiosInstance = axios.create({});

let alreadyFetched = false;

let subscribes = [];

const onAccessTokenFetched = (accessToken) => {
  subscribes = subscribes.filter((callback) => callback(accessToken));
};

const addSubscriber = (callback) => {
  subscribes.push(callback);
};

axiosInstance.interceptors.request.use(
  (req) => {
    authTokens = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    req.headers.Authorization = `Bearer ${authTokens?.token}`;

    return req;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    authTokens = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    const { config, response } = error;
    const originalReq = config;

    let url = window.location.pathname === "/login";

    if (response && response.status === 401 && !url) {
      if (!alreadyFetched) {
        alreadyFetched = true;
        const data = {
          accessToken: authTokens.token,
          refreshToken: authTokens.refreshToken,
        };

        axios
          .post(IDENTITYSERVICE.REFRESH, data)
          .then((response) => {
            localStorage.setItem("user", JSON.stringify(response.data));
            alreadyFetched = false;
            onAccessTokenFetched(response.data.token);
          })
          .catch(() => {
            localStorage.removeItem("user");
            localStorage.removeItem("userDetails");
            window.location = "/";
          });
      }

      const retryOriginalReq = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          originalReq.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axiosInstance(originalReq));
        });
      });
      return retryOriginalReq;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
