import React, { useState, useContext, useEffect } from "react";
import Header from "../../components/Header/Header";
import Arrow from "../../images/icons/Circular_blue_button.png";
import { getClientProgramme } from "../../context/programmeContext/apiCalls";
import GlobalError from "../../components/GetZen/GlobalError";
import FilterSideBar from "../../components/sidebars/FilterSideBar";
import { Link, matchPath } from "react-router-dom";
import SortSideBar from "../../components/sidebars/SortSideBar";
import { MainContext } from "../../context/MainContext";
import Button from "../../components/GetZen/Button/Button";
import Input from "../../components/GetZen/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Index = () => {
  const {
    userData,
    dispatch,
    error,
    selectedFilter,
    setSelectedFilter,
    globalProgrammeList,
    setGlobalProgrammeList,
    selectedProgrammeCount,
  } = useContext(MainContext);
  const [programmeData, setProgrammeData] = useState({
    pageIndex: 1,
    pageSize: 20,
    programmeName: "",
    programmeSearchFilter: "",
    programmeTypeId: 0,
    programmeApproachId: 0,
    presentingChallengeId: 0,
    workplaceThemeId: 0,
    programmeDeliveryId: 0,
    calendarEventId: 0,
    sortDir: "ASC",
  });
  const [globaleError, setGlobalError] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [programmeList, setProgrammeList] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [render, setRender] = useState(true);
  const [pathMatched, setPathMatched] = useState(false);
  const [loadMoreClicked, setLoadMoreClicked] = useState(false);
  const [programmeSearchInputValue, setProgrammeSearchInputValue] =
    useState("");

  let prevUrl = sessionStorage.getItem("prevPath");
  let goD = sessionStorage.getItem("goD");

  useEffect(() => {
    if (prevUrl) {
      if (matchPath("/our-programme/:programmeId", prevUrl)) {
        setPathMatched(true);
      } else {
        setPathMatched(false);
      }
    }
  }, [prevUrl]);

  useEffect(() => {
    if (pathMatched && Object.keys(selectedFilter).length !== 0) {
      setProgrammeSearchInputValue(selectedFilter.programmeSearchFilter);
      setProgrammeData((g) => ({
        ...g,
        programmeName: selectedFilter.programmeName
          ? selectedFilter.programmeName
          : "",
        programmeSearchFilter: selectedFilter.programmeSearchFilter
          ? selectedFilter.programmeSearchFilter
          : "",
        programmeTypeId: selectedFilter.type ? selectedFilter.type.value : 0,
        programmeApproachId: selectedFilter.approach
          ? selectedFilter.approach.value
          : 0,
        presentingChallengeId: selectedFilter.challenges
          ? selectedFilter.challenges.value
          : 0,
        workplaceThemeId: selectedFilter.themes
          ? selectedFilter.themes.value
          : 0,
        programmeDeliveryId: selectedFilter.delivery
          ? selectedFilter.delivery.value
          : 0,
        calendarEventId: selectedFilter.events
          ? selectedFilter.events.value
          : 0,
        sortDir: selectedFilter.sort ? selectedFilter.sort.value : "",
      }));
    }
  }, [pathMatched, selectedFilter]);

  useEffect(() => {
    let isApiSubscribed = true;
    if (pathMatched && Object.keys(selectedFilter).length !== 0) {
      setProgrammeList(globalProgrammeList);
      setHasNextPage(selectedFilter.hasNextPage);
    } else {
      if (render) {
        getClientProgramme(programmeData, dispatch)
          .then((response) => {
            if (isApiSubscribed) {
              setRender(false);
              if (loadMoreClicked) {
                setProgrammeList((g) => [...g, ...response.data.data]);
                setLoadMoreClicked(false);
              } else {
                setProgrammeList(() => [...response.data.data]);
              }
              setHasNextPage(response.data.hasNextPage);
            }
          })
          .catch((err) => {
            setGlobalError((g) => [...g, err.response.data]);
            setRender(false);
            setLoadMoreClicked(false);
          });
      }
    }
    return () => {
      isApiSubscribed = false;
    };
  }, [
    programmeData,
    dispatch,
    globalProgrammeList,
    render,
    loadMoreClicked,
    selectedFilter,
    pathMatched,
  ]);

  function showFilter() {
    setFilterShow(true);
  }

  function filterCloseButton(data) {
    setFilterShow(data);
  }

  function showSortFilter() {
    setSortShow(true);
  }

  function sortFilterCloseButton(data) {
    setSortShow(data);
  }

  const getData = (d) => {
    setSelectedFilter((g) => ({
      ...g,
      ...d,
      hasNextPage: hasNextPage,
      pageIndex: programmeData.pageIndex,
      pageSize: programmeData.pageSize,
      programmeName: programmeData.programmeName,
    }));
    if (prevUrl) {
      if (pathMatched && Object.keys(selectedFilter).length !== 0) {
        setProgrammeSearchInputValue(selectedFilter.programmeSearchFilter);
        setProgrammeData((g) => ({
          ...g,
          programmeTypeId: d.type ? d.type.value : 0,
          programmeApproachId: d.approach ? d.approach.value : 0,
          presentingChallengeId: d.challenges ? d.challenges.value : 0,
          workplaceThemeId: d.themes ? d.themes.value : 0,
          programmeDeliveryId: d.delivery ? d.delivery.value : 0,
          calendarEventId: d.events ? d.events.value : 0,
          sortDir: selectedFilter.sort ? selectedFilter.sort.value : "",
          programmeSearchFilter: selectedFilter.programmeSearchFilter,
        }));
      } else if (!pathMatched && goD === "true") {
        setProgrammeData((g) => ({
          ...g,
          programmeTypeId: d.type ? d.type.value : 0,
          programmeApproachId: d.approach ? d.approach.value : 0,
          presentingChallengeId: d.challenges ? d.challenges.value : 0,
          workplaceThemeId: d.themes ? d.themes.value : 0,
          programmeDeliveryId: d.delivery ? d.delivery.value : 0,
          calendarEventId: d.events ? d.events.value : 0,
          sortDir: "",
          programmeSearchFilter: "",
        }));
      } else {
        setProgrammeData((g) => ({
          ...g,
          programmeTypeId: d.type ? d.type.value : 0,
          programmeApproachId: d.approach ? d.approach.value : 0,
          presentingChallengeId: d.challenges ? d.challenges.value : 0,
          workplaceThemeId: d.themes ? d.themes.value : 0,
          programmeDeliveryId: d.delivery ? d.delivery.value : 0,
          calendarEventId: d.events ? d.events.value : 0,
        }));
      }
    }
  };

  const getSortData = (d) => {
    setSelectedFilter((g) => ({ ...g, ...d }));
    if (prevUrl) {
      if (pathMatched && Object.keys(selectedFilter).length !== 0) {
        setProgrammeSearchInputValue(selectedFilter.programmeSearchFilter);
        setProgrammeData((g) => ({
          ...g,
          sortDir: d.sort ? d.sort.value : "",
          programmeTypeId: selectedFilter.type ? selectedFilter.type.value : 0,
          programmeSearchFilter: selectedFilter.programmeSearchFilter,
          programmeApproachId: selectedFilter.approach
            ? selectedFilter.approach.value
            : 0,
          presentingChallengeId: selectedFilter.challenges
            ? selectedFilter.challenges.value
            : 0,
          workplaceThemeId: selectedFilter.themes
            ? selectedFilter.themes.value
            : 0,
          programmeDeliveryId: selectedFilter.delivery
            ? selectedFilter.delivery.value
            : 0,
          calendarEventId: selectedFilter.events
            ? selectedFilter.events.value
            : 0,
        }));
      } else if (!pathMatched && goD === "true") {
        setProgrammeData((g) => ({
          ...g,
          sortDir: d.sort ? d.sort.value : "",
          programmeTypeId: null,
          programmeApproachId: null,
          presentingChallengeId: null,
          workplaceThemeId: null,
          programmeDeliveryId: null,
          calendarEventId: null,
          programmeSearchFilter: "",
        }));
      } else {
        setProgrammeData((g) => ({
          ...g,
          sortDir: d.sort ? d.sort.value : "",
        }));
      }
    }
  };

  const goToDetailPage = () => {
    sessionStorage.setItem("goD", true);
    setGlobalProgrammeList(programmeList);
    setSelectedFilter((g) => ({
      ...g,
      hasNextPage: hasNextPage,
      pageIndex: programmeData.pageIndex,
      pageSize: programmeData.pageSize,
      programmeName: programmeData.programmeName,
      programmeSearchFilter: programmeData.programmeSearchFilter,
      // sort: { value: programmeData.sortDir },
    }));
  };

  function loadMore() {
    setRender(true);
    setLoadMoreClicked(true);
    if (!pathMatched || Object.keys(selectedFilter).length === 0) {
      if (hasNextPage) {
        setProgrammeData((g) => ({ ...g, pageIndex: g.pageIndex + 1 }));
      }
    }
    if (pathMatched && Object.keys(selectedFilter).length !== 0) {
      if (hasNextPage) {
        setProgrammeSearchInputValue(selectedFilter.programmeSearchFilter);
        sessionStorage.setItem("prevPath", "/");
        setProgrammeData((g) => ({
          ...g,
          pageIndex: selectedFilter.pageIndex + 1,
          pageSize: 20,
          programmeName: selectedFilter.programmeName,
          programmeSearchFilter: selectedFilter.programmeSearchFilter,
          programmeTypeId: selectedFilter.type ? selectedFilter.type.value : 0,
          programmeApproachId: selectedFilter.approach
            ? selectedFilter.approach.value
            : 0,
          presentingChallengeId: selectedFilter.challenges
            ? selectedFilter.challenges.value
            : 0,
          workplaceThemeId: selectedFilter.themes
            ? selectedFilter.themes.value
            : 0,
          programmeDeliveryId: selectedFilter.delivery
            ? selectedFilter.delivery.value
            : 0,
          calendarEventId: selectedFilter.events
            ? selectedFilter.events.value
            : 0,
          sortDir: selectedFilter.sort ? selectedFilter.sort.value : "",
        }));
      }
    }
  }

  function buttonClicked(e) {
    setRender(true);
    setProgrammeData((g) => ({ ...g, pageIndex: 1 }));
  }

  function clickedSort() {
    setRender(true);
    setProgrammeData((g) => ({ ...g, pageIndex: 1 }));
  }
  function programmeSearchBtnClick() {
    sessionStorage.setItem("prevPath", "/");
    setRender(true);
    setProgrammeData((g) => ({
      ...g,
      pageIndex: 1,
      programmeSearchFilter: programmeSearchInputValue,
    }));
  }

  const programmeSearchInputOnchange = (e) => {
    setProgrammeSearchInputValue(e.target.value);
  };

  const programmeSearchInputValueClear = () => {
    setProgrammeSearchInputValue("");
  };

  const searchKeyboardFunction = (event) => {
    if(event.key === "Enter") {
      programmeSearchBtnClick()
    }
  }

  return (
    <>
      <Header title="Booking" subnav={false} headerAction="Add Booking Item" />
      <div className="container">
        <div className="row mt-3">
          <div className="col-12">
            <div className="mt-5">
              <div>
                <h3 className="mb-3">Our programmes</h3>
              </div>
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="programme-search-filter-container pt-2 pb-2 ps-3 pe-md-0 pe-3">
              <div className="row flex-column-reverse flex-md-row ">
                <div className="col-lg-9 col-md-7 col-12 d-flex align-items-center mt-md-0 mt-2 ">
                  <div className="input-group">
                    <div className="form-outline search-box">
                      <Input
                        type="search"
                        id="programme-search"
                        className="form-control form-control-sm search-input"
                        value={programmeSearchInputValue}
                        onChange={programmeSearchInputOnchange}
                        onKeyDown={searchKeyboardFunction}
                      />
                      {programmeSearchInputValue ? (
                        <span
                          className="searchinput-clear-btn spn-c-p"
                          onClick={programmeSearchInputValueClear}
                        >
                          <FontAwesomeIcon
                            icon={faClose}
                            color={"#9f9f9f"}
                            title="Clear"
                          />
                        </span>
                      ) : null}
                    </div>
                    <Button
                      type="button"
                      className="btn btn-getzenprimary btn-sm programme-search-btn-zindex"
                      onClick={programmeSearchBtnClick}
                    >
                      Search
                    </Button>
                  </div>
                </div>
                <div className="col-lg-3 col-md-5 col-12">
                  <div className="d-flex align-items-center justify-content-end me-2">
                    <div className="d-flex align-items-center">
                      <span className="fw-bold me-2">Sort by</span>
                      <button
                        className="rounded-circle sortby border-0"
                        onClick={showSortFilter}
                      ></button>
                    </div>
                    <div className="d-flex align-items-center ms-3">
                      <span className="fw-bold me-2">Filter by</span>
                      <button
                        className="rounded-circle circle-icon filter position-relative border-0"
                        onClick={showFilter}
                      >
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary text-dark">
                          {selectedProgrammeCount}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {programmeList && programmeList.length < 1 ? (
            <center>
              <h1>Programmes not found</h1>
              <p>
                We're sorry. We cannot find any matches for your search term.{" "}
              </p>
            </center>
          ) : (
            programmeList &&
            programmeList.map((item, Index) => (
              <div
                key={Index}
                className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 pb-4"
              >
                <div className="card border-0 shadow">
                  <div className="position-absolute">
                    {/* <button className="btn btn-sm bg-white rounded-pill m-3 fw-light">
                    <small className="text-capitalize">
                      {item.virtual_or_onsite}
                    </small>
                  </button> */}
                  </div>
                  <img
                    src={item.imageUrl}
                    className="card-img-top"
                    alt="image1"
                  />
                  <div className="card-body card-body d-flex flex-column ">
                    <div>
                      <h5 className="card-title text-capitalize">
                        {item.name}
                      </h5>
                      <p className="card-text">
                        {item.programmeTypeName} | {item.amountOfAttendees}{" "}
                        {item.amountOfAttendees === "1" ? "Person" : "People"}
                      </p>
                    </div>
                    <div className="text-end mt-auto">
                      <Link
                        to={
                          userData === "EMPL"
                            ? "/book-programme/" + item.programmeId
                            : "/our-programme/" + item.programmeId
                        }
                        onClick={() => goToDetailPage()}
                      >
                        <img src={Arrow} alt="arrow" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="d-flex mt-5">
        {hasNextPage ? (
          <button
            className="btn btn-sm btn-outline rounded-0 border-2 mb-4 mx-auto px-3 py-2 fa-1sm"
            onClick={loadMore}
          >
            Load More
          </button>
        ) : null}
      </div>
      {error ? <GlobalError message={globaleError} /> : null}
      <SortSideBar
        display={sortShow}
        closeButton={sortFilterCloseButton}
        getData={(d) => getSortData(d)}
        clickedSort={clickedSort}
      />
      <FilterSideBar
        display={filterShow}
        closeButton={filterCloseButton}
        getData={(d) => getData(d)}
        buttonClicked={buttonClicked}
      />
    </>
  );
};

export default Index;
