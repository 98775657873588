import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

function GetPrevUrl() {
  const location = useLocation();
  const storePathValues = useCallback(() => {
    const storage = sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem("currentPath");
    storage.setItem("prevPath", prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    storePathValues();
  }, [storePathValues]);
}

export default GetPrevUrl;
