const IdentityServiceReducer = (state, action) => {
  switch (action.type) {
    case "REGISTER_EMPLOYEE_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isRegisterEmployeeFetching: true,
        },
        error: false,
      };
    case "REGISTER_EMPLOYEE_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isRegisterEmployeeFetching: false,
        },
        error: false,
      };
    case "REGISTER_EMPLOYEE_FAILURE":
      return {
        ...state,
        errorData: action.payload,
        identityFetchingState: {
          ...state.identityFetchingState,
          isRegisterEmployeeFetching: false,
        },
        error: true,
      };
    case "RESET_PASSWORD_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isResetPasswordFetching: true,
        },
        error: false,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isResetPasswordFetching: false,
        },
        error: false,
      };
    case "RESET_PASSWORD_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isResetPasswordFetching: false,
        },
        error: true,
      };
    case "GET_USER_PROFILE_PICTURE_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isGetUserProfilePictureFetching: true,
        },
        error: false,
      };
    case "GET_USER_PROFILE_PICTURE_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isGetUserProfilePictureFetching: false,
        },
        error: false,
      };
    case "GET_USER_PROFILE_PICTURE_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isGetUserProfilePictureFetching: false,
        },
        error: true,
      };
    case "ADMIN_REST_PASSWORD_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAdminRestPasswordLinkFetching: true,
        },
        error: false,
      };
    case "ADMIN_REST_PASSWORD_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAdminRestPasswordLinkFetching: false,
        },
        error: false,
      };
    case "ADMIN_RESET_PASSWORD_FAILURE":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isAdminRestPasswordLinkFetching: false,
        },
        error: true,
      };
    case "UPDATE_EMPLOYER_ADMIN_DETAILS_START":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isUpdateEmployerAdminDetailsFetching: true,
        },
        error: false,
      };
    case "UPDATE_EMPLOYER_ADMIN_DETAILS_SUCCESS":
      return {
        ...state,
        identityFetchingState: {
          ...state.identityFetchingState,
          isUpdateEmployerAdminDetailsFetching: false,
        },
        error: false,
      };
    case "UPDATE_EMPLOYER_ADMIN_DETAILS_FAILURE":
      return {
        ...state,
        errorData: action.payload,
        identityFetchingState: {
          ...state.identityFetchingState,
          isUpdateEmployerAdminDetailsFetching: false,
        },
        error: true,
      };

    default: {
      return { ...state };
    }
  }
};

export default IdentityServiceReducer;
