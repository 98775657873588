import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import { MainContext } from "../../context/MainContext";
import {
  bookSlot,
  getProgrammeBookingDetailsForClientPortal,
} from "../../context/programmeContext/apiCalls";
import moment from "moment";
import GlobalError from "../../components/GetZen/GlobalError";
import Alert from "../../components/GetZen/Alert";
import momentTimeZone from "moment-timezone";

function BookingDetailsTwo() {
  const { programmeId } = useParams();
  const { dispatch, error } = useContext(MainContext);
  const [bookingDetails, setBookingDetails] = useState({});
  const [globaleError, setGlobalError] = useState([]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [canBooking, setCanBooking] = useState(true);
  const [isError, setIsError] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [pageRefresh, setPageRefresh] = useState(true);

  setTimeout(() => {
    if (alertDisplay === true) {
      setAlertDisplay(false);
      setIsError(false);
    }
  }, 5000);

  useEffect(() => {
    let isApiSubscribed = true;

    if (pageRefresh) {
      getProgrammeBookingDetailsForClientPortal(
        { programmeBookingId: programmeId },
        dispatch
      )
        .then((response) => {
          if (isApiSubscribed) {
            setBookingDetails(response.data);
            setCanBooking(response.data.canBooking);
            setPageRefresh(false);
          }
        })
        .catch((err) => {
          setGlobalError((g) => [...g, err.response.data]);
          setIsError(true);
          setPageRefresh(false);
        });
    }
    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [programmeId, pageRefresh, dispatch]);

  const bookSlotClick = () => {
    const bookSlotPayload = {
      programmingBookingId: programmeId,
      LocalStartSlotDateTime: moment
        .utc(bookingDetails.clientProgrammeBookingDetails.startSlotTime)
        .local()
        .format("YYYY-MM-DD hh:mm a"),
      LocalTimeZone: momentTimeZone.tz.guess(),
    };

    bookSlot(bookSlotPayload, dispatch)
      .then(() => {
        setAlertMessage("Your slot is booked.");
        setAlertDisplay(true);
        setAlertType("Success");
        setCanBooking(false);
        setPageRefresh(true);
      })
      .catch((err) => {
        setGlobalError([err.response.data]);
      });
  };

  return (
    <>
      <Header title="Booking" subnav={false} headerAction="Add Booking Item" />
      <div className="container">
        <div className="row mt-3">
          {bookingDetails.programmeDetails ? (
            <div className="col-12 col-sm-12 col-md-5 ">
              {/* <div className="pt-3">
              <button className="active btn btn-sm btn-getzenprimary rounded-pill text-capitalize">
                {state.sessionTypeName}
              </button>
            </div> */}
              <h1 className="py-3 text-capitalize mt-3">
                {bookingDetails.programmeDetails.name}
              </h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: bookingDetails.programmeDetails.shortDescription,
                }}
              ></div>
              <div className="border-1 border-bottom py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Programme type:</div>
                  <div>{bookingDetails.programmeDetails.programmeTypeName}</div>
                </div>
              </div>
              <div className="border-1 border-bottom py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Attendees:</div>
                  <div>
                    {bookingDetails.programmeDetails.amountOfAttendees}{" "}
                    {bookingDetails.programmeDetails.amountOfAttendees === "1"
                      ? "Person"
                      : "People"}
                  </div>
                </div>
              </div>
              <div className="border-1 border-bottom py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Start Time:</div>
                  <div>
                    {moment
                      .utc(
                        bookingDetails.clientProgrammeBookingDetails
                          .startSlotTime
                      )
                      .local()
                      .format("YYYY-MM-DD hh:mm A")}
                  </div>
                </div>
              </div>
              <div className="border-1 border-bottom py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">End Time:</div>
                  <div>
                    {moment
                      .utc(
                        bookingDetails.clientProgrammeBookingDetails.endSlotTime
                      )
                      .local()
                      .format("YYYY-MM-DD hh:mm A")}
                  </div>
                </div>
              </div>
              <div className="py-3">
                <div className="d-flex justify-content-start">
                  <div>
                    <h1>
                      Availability{" "}
                      {bookingDetails.clientProgrammeBookingDetails.bookedSlots}
                      /{bookingDetails.clientProgrammeBookingDetails.noOfSlots}
                    </h1>
                  </div>
                </div>
              </div>
              <div>
                {bookingDetails.clientProgrammeBookingDetails ? (
                  <div className="col-12 col-sm-12 col-md-7 py-3">
                    <button
                      className="btn btn-sm btn-outline rounded-0 border-2"
                      onClick={() => bookSlotClick()}
                      disabled={!canBooking}
                    >
                      Book your place
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12 col-sm-12 col-md-7 d-flex align-items-center justify-content-center mt-3">
              <div className="proDetails">
                <img
                  src={bookingDetails.programmeDetails.imageUrl}
                  alt="img"
                  className="img"
                />
              </div>
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12 mt-3">
              <hr />
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12 col-sm-12 col-md-5 ">
              <h3>About the session</h3>
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12  col-sm-12 col-md-7 ">
              <div className="fa-1x fw-bold mb-3">Description</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: bookingDetails.programmeDetails.description,
                }}
              ></div>
              <div className="mt-5">
                <p>
                  <strong>Programme type: </strong>
                  {bookingDetails.programmeDetails.programmeTypeName}
                </p>
                {bookingDetails.programmeDetails.durationFormatToDisplay ? (
                  <p>
                    <strong>Duration: </strong>
                    {bookingDetails.programmeDetails.durationFormatToDisplay}
                  </p>
                ) : null}
                <p>
                  <strong>Location: </strong>
                  {bookingDetails.programmeDetails.programmeDeliveryName}
                </p>
                {bookingDetails.programmeDetails.sessionRequirments ? (
                  <div>
                    <strong>Requirements: </strong>
                    <div
                      className="d-inline-block"
                      dangerouslySetInnerHTML={{
                        __html:
                          bookingDetails.programmeDetails.sessionRequirments,
                      }}
                    ></div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12">
              <hr />
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12 col-sm-12 col-md-5 ">
              <h3>Agenda</h3>
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12  col-sm-12 col-md-7 ">
              <div
                dangerouslySetInnerHTML={{
                  __html: bookingDetails.programmeDetails.sessionAgenda,
                }}
              ></div>
            </div>
          ) : null}
          {bookingDetails.programmeDetails ? (
            <div className="col-12">
              <hr />
            </div>
          ) : null}
          {bookingDetails.clientProgrammeBookingDetails ? (
            <div className="col-12 col-sm-12 col-md-5">
              <h3>Practitioner </h3>
            </div>
          ) : null}
          {bookingDetails.clientProgrammeBookingDetails ? (
            <div className="col-12 col-sm-12 col-md-7">
              <img
                src={
                  bookingDetails.clientProgrammeBookingDetails.profileImageUrl
                }
                className="rounded-circle border border-2 profile-border"
                alt=""
              />
              <p className="mt-2 mb-1">
                {bookingDetails.clientProgrammeBookingDetails.firstName}{" "}
                {bookingDetails.clientProgrammeBookingDetails.surname}
              </p>
              <div
                className="fw-bold"
                dangerouslySetInnerHTML={{
                  __html:
                    bookingDetails.clientProgrammeBookingDetails
                      .shortDescription,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    bookingDetails.clientProgrammeBookingDetails.description,
                }}
              ></div>
            </div>
          ) : null}
          {bookingDetails.clientProgrammeBookingDetails ? (
            <div className="col-12">
              <hr />
            </div>
          ) : null}
          <div className="col-12 col-sm-12 col-md-5"></div>
          {bookingDetails.clientProgrammeBookingDetails ? (
            <div className="col-12 col-sm-12 col-md-7 py-3">
              <button
                className="btn btn-sm btn-outline rounded-0 border-2"
                onClick={() => bookSlotClick()}
                disabled={!canBooking}
              >
                Book your place
              </button>
            </div>
          ) : null}
        </div>
      </div>
      {error || isError ? <GlobalError message={globaleError} /> : null}
      <Alert
        message={alertMessage}
        display={alertDisplay}
        statusType={alertType}
      />
    </>
  );
}

export default BookingDetailsTwo;
