import { Navigate } from "react-router-dom";

function LoginProtectRoute({ children, user, roleType }) {
  return user ? (
    roleType === "EMPL" ? (
      <Navigate to="/mybooking" />
    ) : (
      <Navigate to="/our-programme" />
    )
  ) : (
    children
  );
}

export default LoginProtectRoute;
