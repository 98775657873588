import useAxios from "../../core/jwt/useAxios";
import { COMPANY } from "../../core/service/api";
import {
  getCompanyDetailStart,
  getCompanyDetailFailure,
  getCompanyDetailSuccess,
} from "./CompanyActions";

//Get Company Detailes
export const getCompanyDetailes = async (id, dispatch) => {
  dispatch(getCompanyDetailStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .get(COMPANY.GET_DETAILS_FOR_EMPLOYEE_REGISTRATION(id))
      .then((response) => {
        dispatch(getCompanyDetailSuccess(response && response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(getCompanyDetailFailure());
        reject(err);
      });
  });
};
