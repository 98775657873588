const COMPANY_INITIAL_FETCHING_VALUES = {
  isGetCompanyDetailesFetching: false,
};

export const COMPANY_INITIAL_VALUES = {
  error: false,
  companyFetchingState: {
    ...COMPANY_INITIAL_FETCHING_VALUES,
  },
};
