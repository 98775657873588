import { Navigate, useLocation } from "react-router-dom";
import GetPrevUrl from "./GetPrevUrl";

function ProtectRoute({ children, user }) {
  const location = useLocation();

  GetPrevUrl();

  return user ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ prevUrl: location.pathname }} />
  );
}

export default ProtectRoute;
