import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../../components/Header2/Header";
import Input from "../../components/GetZen/Input";
import { DatePicker, Space } from "antd";
import CustomSelect from "../../components/GetZen/Select/select/Select";
import { getCompanyDetailes } from "../../context/companyContext/apiCalls";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  UpdateEmployerAdminDetails,
  registerEmployee,
} from "../../context/IdentityServiceContext/apiCalls";
import Alert from "../../components/GetZen/Alert";
import moment from "moment";
import GlobalError from "../../components/GetZen/GlobalError";
import { MainContext } from "../../context/MainContext";
import SharedData from "../../helpers/SharedData";
import { getEmployerAdminDetailsForRegistrationComplete } from "../../context/EmployeeContext/apiCalls";
import { DpObjectLabelHandle } from "../../helpers/HelperFun";
import PasswordValidationInfoPopup from "../../components/GetZen/PasswordValidationInfoPopup/PasswordValidationInfoPopup";

export default function Register() {
  const [searchParams] = useSearchParams();
  const cid = searchParams.get("cid");
  const SharedDataList = SharedData();
  const { dispatch, errorData, error, employerAdminDetailsForRegistration } =
    useContext(MainContext);
  const [globaleError, setGlobalError] = useState([]);
  const mode = searchParams.get("mode");
  const employerUserId = searchParams.get("uid");
  const Navigate = useNavigate();

  const [initialValue, setintialValue] = useState({
    firstname: "",
    surname: "",
    email: "",
    mobilenumber: "",
    date: "",
    gender: "",
    designation: "",
    department: "",
    file: null,
    password: "",
    confirmpassword: "",
  });

  const [companyDetails, setCompanyDetails] = useState({
    companyName: "",
    industry: "",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [alertDisplay, setAlertDisplay] = useState(false);
  const logoRef = useRef();
  const [alertType, setAlertType] = useState("");
  const dateFormat = "DD/MM/YYYY";

  useEffect(() => {
    getCompanyDetailes(cid, dispatch)
      .then((response) => {
        setCompanyDetails((g) => ({
          ...g,
          companyName: response.data.companyName,
          industry: response.data.industry,
        }));
      })
      .catch((err) => {
        setGlobalError((g) => [...g, err.response.data]);
      });
  }, [dispatch, cid]);

  useEffect(() => {
    setGlobalError((g) => [...g, SharedDataList.globaleError.errorData]);
  }, [SharedDataList]);

  useEffect(() => {
    if (mode === "cadm-update") {
      getEmployerAdminDetailsForRegistrationComplete(
        employerUserId,
        dispatch
      ).catch((err) => {
        setGlobalError((g) => [...g, err.response.data]);
      });
    }
  }, [dispatch, employerUserId]);

  useEffect(() => {
    if (employerAdminDetailsForRegistration) {
      setintialValue((g) => ({
        ...g,
        firstname: employerAdminDetailsForRegistration.firstName,
        surname: employerAdminDetailsForRegistration.surName,
        email: employerAdminDetailsForRegistration.email,
        mobilenumber: employerAdminDetailsForRegistration.phoneNumber,
        date: moment(employerAdminDetailsForRegistration.dob).format(
          dateFormat
        ),
        gender: DpObjectLabelHandle(
          employerAdminDetailsForRegistration.gender,
          SharedDataList.Gender
        ),
        designation: DpObjectLabelHandle(
          employerAdminDetailsForRegistration.designationName,
          SharedDataList.Designations
        ),
        department: DpObjectLabelHandle(
          employerAdminDetailsForRegistration.departmentName,
          SharedDataList.Departments
        ),
        password: "",
        confirmpassword: "",
        companyId: employerAdminDetailsForRegistration.companyId,
        userId: employerAdminDetailsForRegistration.userId,
        passwordResetToken:
          employerAdminDetailsForRegistration.passwordResetToken,
      }));
    }
  }, [employerAdminDetailsForRegistration, SharedDataList]);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/webp",
  ];

  /* eslint-disable */
  // Yup Validation
  let validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required!"),
    surname: Yup.string().required("Surname is required!"),
    email: Yup.string()
      .required("Email is required!")
      .email("Please enter valid email!"),
    mobilenumber: Yup.string().required(" Mobile Number is reqiured!"),
    date: Yup.string().required("Date of Birth is required!").nullable(),
    gender: Yup.object().required("Gender is required!"),
    designation: Yup.object().required("Designation is required!"),
    department: Yup.object().required("Department is required!"),
    file: Yup.mixed()
      .nullable()
      .test(
        "FILE_SIZE",
        "Uploaded file is too big. Please upload image below 5mb.",
        (value) => !value || (value && value.size <= 5242880)
      )
      .test(
        "FILE_FORMAT",
        "Uploaded file has unsupported format.",
        (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
      ),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .required("Password is required!")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(/^(?=.*[0-9])/, "Must Contain One Digit Character")
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      ),
    confirmpassword: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .oneOf([Yup.ref("password"), null], "Passwords must match!")
      .required("Confrim password is required!"),
  });

  function submit(data, { resetForm }) {
    const date = data.date.toString();
    const arr = date.split("/");
    const updatedDate = arr[2] + "-" + arr[1] + "-" + arr[0];
    let formData = new FormData();
    formData.append("CompanyId", cid);
    formData.append("UserName", data.email);
    formData.append("Email", data.email);
    formData.append("PhoneNumber", data.mobilenumber);
    formData.append("Password", data.password);
    formData.append("FirstName", data.firstname);
    formData.append("SurName", data.surname);
    formData.append("GenderId", data.gender.value);
    formData.append("Dob", updatedDate);
    formData.append("CreditBalance", 0);
    formData.append("RecordStatusId", 1);
    formData.append("DepartmentId", data.department.value);
    formData.append("DesignationId", data.designation.value);
    formData.append("ProfilePic", data.file);
    formData.append("CompanyIdEncrypted", cid);

    if (mode === "cadm-update") {
      formData.delete("CompanyId");
      formData.append("CompanyId", data.companyId);
      formData.append("UserId", employerUserId);
      formData.append("PasswordResetToken", data.passwordResetToken);

      UpdateEmployerAdminDetails(formData, dispatch)
        .then((response) => {
          setAlertMessage(response.data.message);
          setAlertDisplay(true);
          setAlertType("Success");
          setTimeout(() => {
            setAlertDisplay(false);
            Navigate("/");
          }, 3000);
        })
        .catch((err) => {
          setGlobalError([err.response.data]);
        });
    } else {
      registerEmployee(formData, dispatch)
        .then((response) => {
          setAlertMessage(response.data.message);
          setAlertDisplay(true);
          setAlertType("Success");
          setTimeout(() => {
            setAlertDisplay(false);
            Navigate("/");
          }, 3000);
          resetForm();
          logoRef.current.value = null; //THIS RESETS THE FILE FIELD
        })
        .catch((err) => {
          setGlobalError([err.response.data]);
        });
    }
  }

  function disabledDate(current) {
    // Can not select days After today and today
    return current && current.valueOf() > Date.now();
  }

  return (
    <>
      <Header />
      <div className="container">
        <div className="row mb-3">
          <h3>
            {mode === "cadm-update" ? "Employer" : "Employee"} Registration
          </h3>
          <div className="row bg-white shadow-sm mt-3 px-2 pb-3">
            <h3 className="fa-1x mt-3">
              {mode === "cadm-update" ? "Company" : "Employer"} Details
            </h3>
            <hr className="mb-3"></hr>
            <div className="col-12 col-md-4">
              <label className="form-label fw-bold f-16">Company name : </label>
              <span className="f-16"> {companyDetails.companyName}</span>
            </div>
            <div className="col-12 col-md-4 mb-3">
              <label className="form-label fw-bold f-16">Industry :</label>
              <span className="f-16"> {companyDetails.industry}</span>
            </div>
          </div>
          {error
            ? errorData !== undefined
              ? errorData.errors !== undefined && errorData.errors !== null
                ? errorData.errors.map((item, index) => (
                    <div className="row alert alert-primary mt-4">
                      <li key={index}>{item}</li>
                    </div>
                  ))
                : null
              : null
            : null}
          <div className="row bg-white shadow-sm mt-3 px-2 pb-3">
            <h3 className="fa-1x mt-3">
              {" "}
              {mode === "cadm-update" ? "Employer" : "Employee"} Details
            </h3>
            <hr className="mb-3"></hr>
            <Formik
              initialValues={initialValue}
              validationSchema={validationSchema}
              onSubmit={submit}
              enableReinitialize
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="firstname" className="form-label f-16">
                          First Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control form-control-sm"
                          name="firstname"
                          type="text"
                          value={props.values.firstname}
                          onChange={props.handleChange}
                        />
                        <ErrorMessage
                          name="firstname"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="surname" className="form-label f-16">
                          Surname <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control form-control-sm"
                          name="surname"
                          type="text"
                          value={props.values.surname}
                          onChange={props.handleChange}
                        />
                        <ErrorMessage
                          name="surname"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="email" className="form-label f-16">
                          Email <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control form-control-sm"
                          name="email"
                          type="text"
                          value={props.values.email}
                          onChange={props.handleChange}
                          readonly={mode === "cadm-update" ? true : false}
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label
                          htmlFor="mobilenumber"
                          className="form-label f-16"
                        >
                          Mobile Number <span style={{ color: "red" }}>*</span>
                        </label>
                        <Input
                          className="form-control form-control-sm"
                          name="mobilenumber"
                          type="text"
                          value={props.values.mobilenumber}
                          onChange={props.handleChange}
                        />
                        <ErrorMessage
                          name="mobilenumber"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="date" className="form-label f-16">
                          Date of Birth (DD/MM/YYYY){" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Space direction="vertical">
                          <DatePicker
                            name="date"
                            className="form-control form-control-sm"
                            onChange={(date, dateString) =>
                              props.setFieldValue("date", dateString)
                            }
                            format={dateFormat}
                            value={
                              props.values.date !== ""
                                ? moment(props.values.date, dateFormat)
                                : ""
                            }
                            disabledDate={disabledDate}
                          />
                        </Space>
                        <ErrorMessage
                          name="date"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="gender" className="form-label f-16">
                          Gender <span style={{ color: "red" }}>*</span>
                        </label>
                        <CustomSelect
                          name="gender"
                          options={SharedDataList.Gender}
                          value={props.values.gender}
                          onChange={props.setFieldValue}
                          isSearchable={false}
                        />
                        <ErrorMessage
                          name="gender"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label
                          htmlFor="designation"
                          className="form-label f-16"
                        >
                          Designation <span style={{ color: "red" }}>*</span>
                        </label>
                        <CustomSelect
                          name="designation"
                          options={SharedDataList.Designations}
                          value={props.values.designation}
                          onChange={props.setFieldValue}
                          isSearchable={true}
                        />
                        <ErrorMessage
                          name="designation"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="department" className="form-label f-16">
                          Departments <span style={{ color: "red" }}>*</span>
                        </label>
                        <CustomSelect
                          name="department"
                          options={SharedDataList.Departments}
                          value={props.values.department}
                          onChange={props.setFieldValue}
                          isSearchable={true}
                        />
                        <ErrorMessage
                          name="department"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="mb-1">
                        <label htmlFor="file" className="form-label f-16">
                          Profile image upload
                        </label>
                        <Input
                          refData={logoRef}
                          name="file"
                          type="file"
                          className="form-control form-select-sm"
                          onChange={(event) => {
                            props.setFieldValue("file", event.target.files[0]);
                          }}
                        />
                        <ErrorMessage
                          name="file"
                          component="span"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                      <label className="form-label f-16">
                        Create password <span style={{ color: "red" }}>* </span>
                        <PasswordValidationInfoPopup />
                      </label>
                      <Input
                        className="form-control form-control-sm"
                        type="password"
                        name="password"
                        value={props.values.password}
                        onChange={props.handleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className="error"
                      />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                      <label className="form-label f-16">
                        Confirm password <span style={{ color: "red" }}>*</span>
                      </label>
                      <Input
                        name="confirmpassword"
                        value={props.values.confirmpassword}
                        onChange={props.handleChange}
                        type="password"
                        className="form-control form-control-sm"
                      />
                      <ErrorMessage
                        name="confirmpassword"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-3 d-flex align-content-lg-start justify-content-end d-flex align-items-end">
                    <div>
                      <button
                        type="submit"
                        className="btn btn-sm btn-getzenprimary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        <Alert
          message={alertMessage}
          display={alertDisplay}
          statusType={alertType}
        />
      </div>
      {error ? <GlobalError message={globaleError} /> : null}
    </>
  );
}
