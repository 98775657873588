const IDENTITY_INITIAL_FETCHING_VALUES = {
  isRegisterEmployeeFetching: false,
  isResetPasswordFetching: false,
  isGetUserProfilePictureFetching: false,
  isAdminRestPasswordLinkFetching: false,
  isUpdateEmployerAdminDetailsFetching: false,
};

export const IDENTITY_INITIAL_VALUES = {
  errorData: [],
  error: false,
  identityFetchingState: {
    ...IDENTITY_INITIAL_FETCHING_VALUES,
  },
};
