import { useState, useEffect, useContext } from "react";
import { MainContext } from "../context/MainContext";
import { getSharedData } from "../context/sharedDataContext/apiCalls";

function SharedData() {
  const { dispatch } = useContext(MainContext);

  const [sharedData, setSharedData] = useState({
    Industries: [],
    BudgetWellBeings: [],
    PaymentDetails: [],
    Gender: [],
    Designations: [],
    Departments: [],
    CompanySize: [],
    Subscriptions: [],
    RecordStatuses: [],
    Country: [],
    ageRangeArray: [],
    Skills: [],
    globaleError: {
      errorData: "",
      status: false,
    },
  });

  useEffect(() => {
    getSharedData(dispatch)
      .then((res) => {
        setSharedData((g) => ({
          ...g,
          Industries: res.data.Industry.map((item) => ({
            label: item.industryName,
            value: item.industryId,
          })),
          BudgetWellBeings: res.data.BudgetWellBeing.map((item) => ({
            label: item.budgetWellBeingName,
            value: item.budgetWellBeingId,
          })),
          Gender: res.data.Gender.map((item) => ({
            label: item.genderName,
            value: item.genderId,
          })),
          Designations: res.data.Designations.map((item) => ({
            label: item.designationName,
            value: item.designationId,
          })),
          Departments: res.data.Departments.map((item) => ({
            label: item.departmentName,
            value: item.departmentId,
          })),
          ageRangeArray: res.data.AgeRange.map((item) => ({
            label: item.ageRangeText,
            value: item.ageRangeId,
          })),
          Country: res.data.Country.map((item) => ({
            label: item.countryName,
            value: item.countryCode,
          })),
          RecordStatuses: res.data.RecordStatuses.map((item) => ({
            label: item.statusName,
            value: item.recordStatusId,
          })),
          PaymentDetails: res.data.PaymentDetails.map((item) => ({
            label: item.paymentDetailsName,
            value: item.paymentDetailsId,
          })),
          CompanySize: res.data.CompanySize.map((item) => ({
            label: item.sizeName,
            value: item.companySizeId,
          })),
          Subscriptions: res.data.Subscriptions.map((item) => ({
            label: item.subscriptionName,
            value: item.subscriptionId,
          })),
          Skills: res.data.Skills,
          globaleError: {
            ...g.globaleError,
          },
        }));
      })
      .catch((err) => {
        setSharedData((g) => ({
          ...g,
          globaleError: {
            ...g.globaleError,
            status: true,
            errorData: err.response.data,
          },
        }));
      });
  }, [setSharedData, dispatch]);

  return sharedData;
}

export default SharedData;
