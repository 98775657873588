export const getSharedDataStart = () => ({
  type: "GET_SHARED_DATA_START",
});

export const getSharedDataSuccess = (sharedData) => ({
  type: "GET_SHARED_DATA_SUCCESS",
  payload: sharedData,
});

export const getSharedDataFailure = () => ({
  type: "GET_SHARED_DATA_FAILTURE",
});
