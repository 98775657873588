import { createContext, useReducer, useEffect, useState } from "react";
import { combineReducers } from "./CombineReducers";
import AuthReducer from "./authContext/AuthReducer";
import ProgrammeReducer from "./programmeContext/ProgrammeReducer";
import SharedDataReducer from "./sharedDataContext/SharedDataReducer";
import CompanyReducer from "./companyContext/CompanyReducer";
import IdentityServiceReducer from "./IdentityServiceContext/IdentityServiceReducer";
import PractitionerReducer from "./practitionerContext/PractitionerReducer";
import EmployeeReducer from "./EmployeeContext/EmployeeReducer";
import { AUTH_INITIAL_VALUES } from "./authContext/AuthContextInitialValues";
import { COMPANY_INITIAL_VALUES } from "./companyContext/CompanyContextInitialValues";
import { EMPLOYEE_INITIAL_VALUES } from "./EmployeeContext/EmployeeContextInitialValues";
import { IDENTITY_INITIAL_VALUES } from "./IdentityServiceContext/IdentityContextInitialValues";
import { PRACTITIONER_INITIAL_VALUES } from "./practitionerContext/practitionerContextInitialValues";
import { PROGRAMME_INITIAL_VALUES } from "./programmeContext/ProgrammeContextInitialValues";
import { SHARED_DATA_INITIAL_VALUES } from "./sharedDataContext/SharedDataContextInitialValues";

const INITIAL_STATE = {
  AuthReducer: {
    ...AUTH_INITIAL_VALUES,
  },
  CompanyReducer: {
    ...COMPANY_INITIAL_VALUES,
  },
  EmployeeReducer: {
    ...EMPLOYEE_INITIAL_VALUES,
  },
  IdentityServiceReducer: {
    ...IDENTITY_INITIAL_VALUES,
  },
  PractitionerReducer: {
    ...PRACTITIONER_INITIAL_VALUES,
  },
  ProgrammeReducer: {
    ...PROGRAMME_INITIAL_VALUES,
  },
  SharedDataReducer: {
    ...SHARED_DATA_INITIAL_VALUES,
  },
};
const appReducers = combineReducers({
  AuthReducer,
  CompanyReducer,
  ProgrammeReducer,
  SharedDataReducer,
  EmployeeReducer,
  IdentityServiceReducer,
  PractitionerReducer,
});

export const MainContext = createContext(INITIAL_STATE);

export const MainContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducers, INITIAL_STATE);
  const [selectedFilter, setSelectedFilter] = useState({});
  const [globalProgrammeList, setGlobalProgrammeList] = useState([]);
  const [selectedProgrammeCount, setSelectedProgrammeCount] = useState();

  const loderStatus = {
    ...state.AuthReducer.authFetchingState,
    ...state.CompanyReducer.companyFetchingState,
    ...state.EmployeeReducer.employeeFetchingState,
    ...state.IdentityServiceReducer.identityFetchingState,
    ...state.PractitionerReducer.practitionerFetchingState,
    ...state.ProgrammeReducer.programmeFetchingState,
    ...state.SharedDataReducer.sharedDataFetchingState,
  };
  const globalLoaderState = Object.values(loderStatus).some((val) => val);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.AuthReducer.user));
    localStorage.setItem(
      "userDetails",
      JSON.stringify(state.AuthReducer.userDetails)
    );
  }, [state.AuthReducer.user, state.AuthReducer.userDetails]);

  return (
    <MainContext.Provider
      value={{
        user: state.AuthReducer.user,
        userData: state.AuthReducer.userData,
        userDetails: state.AuthReducer.userDetails,
        employerAdminDetailsForRegistration:
          state.EmployeeReducer.employerAdminDetailsForRegistration,
        isLoading: globalLoaderState,
        error:
          state.AuthReducer.error ||
          state.PractitionerReducer.error ||
          state.CompanyReducer.error ||
          state.EmployeeReducer.error ||
          state.IdentityServiceReducer.error ||
          state.SharedDataReducer.error ||
          state.ProgrammeReducer.error,
        errorData: state.IdentityServiceReducer.errorData,
        selectedFilter,
        setSelectedFilter,
        globalProgrammeList,
        setGlobalProgrammeList,
        selectedProgrammeCount,
        setSelectedProgrammeCount,
        dispatch,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
