const EmployeeReducer = (state, action) => {
  switch (action.type) {
    case "GET_EMPLOYEE_UP_COMING_PROGRAMME_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeUpcomingProgramsFetching: true,
        },
        error: false,
      };
    case "GET_EMPLOYEE_UP_COMING_PROGRAMME_SUCCESS":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeUpcomingProgramsFetching: false,
        },
        error: false,
      };
    case "GET_EMPLOYEE_UP_COMING_PROGRAMME_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployeeUpcomingProgramsFetching: false,
        },
        error: true,
      };
    case "GET_UP_COMING_SUBSCRIBED_PROGRAMMES_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetUpcomingSubscribedProgramsFetching: true,
        },
        error: false,
      };
    case "GET_UP_COMING_SUBSCRIBED_PROGRAMMESE_SUCCESS":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetUpcomingSubscribedProgramsFetching: false,
        },
        error: false,
      };
    case "GET_UP_COMING_SUBSCRIBED_PROGRAMMES_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetUpcomingSubscribedProgramsFetching: false,
        },
        error: true,
      };
    case "GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE_START":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployerAdminDetailsForRegistrationCompleteFetching: true,
        },
        error: false,
      };
    case "GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE_SUCCESS":
      return {
        ...state,
        employerAdminDetailsForRegistration: action.payload,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployerAdminDetailsForRegistrationCompleteFetching: false,
        },
        error: false,
      };
    case "GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE_FAILURE":
      return {
        ...state,
        employeeFetchingState: {
          ...state.employeeFetchingState,
          isGetEmployerAdminDetailsForRegistrationCompleteFetching: false,
        },
        error: true,
      };
    default:
      return { ...state };
  }
};

export default EmployeeReducer;
