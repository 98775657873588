import React from "react";
import PropTypes from "prop-types";

function Input({
  id,
  name,
  className,
  value,
  placeholder,
  readonly,
  type,
  onChange,
  refVal,
  onKeyDown
}) {
  return (
    <input
      ref={refVal}
      id={id}
      name={name}
      placeholder={placeholder}
      value={value}
      className={className}
      readOnly={readonly}
      type={type}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}

Input.propTypes = {
  id: PropTypes.string,
  value: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  readonly: PropTypes.bool,
  type: PropTypes.string,
};

Input.defaultProps = {
  type: "text",
  options: [],
  col: 12,
};

export default Input;
