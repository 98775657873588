export const getPractitionerListForClientPortalProgrammeStart = () => ({
  type: "GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME_START",
});

export const getPractitionerListForClientPortalProgrammeSuccess = () => ({
  type: "GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME_SUCCESS",
});

export const getPractitionerListForClientPortalProgrammeFailure = () => ({
  type: "GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME_FAILURE",
});

export const getPractitionerDetailsStart = () => ({
  type: "GET_PRACTITIONER_START",
});

export const getPractitionerDetailsSuccess = () => ({
  type: "GET_PRACTITIONER_SUCCESS",
});

export const getPractitionerDetailsFailure = () => ({
  type: "GET_PRACTITIONER_FAILURE",
});
