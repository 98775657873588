export const getEmployeeUpcomingProgramsStart = () => ({
  type: "GET_EMPLOYEE_UP_COMING_PROGRAMME_START",
});

export const getEmployeeUpcomingProgramsSuccess = () => ({
  type: "GET_EMPLOYEE_UP_COMING_PROGRAMME_SUCCESS",
});

export const getEmployeeUpcomingProgramsFailure = () => ({
  type: "GET_EMPLOYEE_UP_COMING_PROGRAMME_FAILURE",
});

export const getUpcomingSubscribedProgramsStart = () => ({
  type: "GET_UP_COMING_SUBSCRIBED_PROGRAMMES_START",
});

export const getUpcomingSubscribedProgramsSuccess = () => ({
  type: "GET_UP_COMING_SUBSCRIBED_PROGRAMMESE_SUCCESS",
});

export const getUpcomingSubscribedProgramsFailure = () => ({
  type: "GET_UP_COMING_SUBSCRIBED_PROGRAMMES_FAILURE",
});

export const getEmployerAdminDetailsForRegistrationCompleteStart = () => ({
  type: "GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE_START",
});

export const getEmployerAdminDetailsForRegistrationCompleteSuccess = (
  data
) => ({
  type: "GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE_SUCCESS",
  payload: data,
});

export const getEmployerAdminDetailsForRegistrationCompleteFailure = () => ({
  type: "GET_EMPLOYER_ADMIN_DETAILS_FOR_REGISTRATION_COMPLETE_FAILURE",
});
