const PractitionerReducer = (state, action) => {
  switch (action.type) {
    case "GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerListForClientPortalProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerListForClientPortalProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_LIST_FOR_CLIENT_PORTAL_PROGRAMME_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerListForClientPortalProgrammeFetching: false,
        },
        error: true,
      };
    case "GET_PRACTITIONER_START":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDetailsFetching: true,
        },
        error: false,
      };
    case "GET_PRACTITIONER_SUCCESS":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDetailsFetching: false,
        },
        error: false,
      };
    case "GET_PRACTITIONER_FAILURE":
      return {
        ...state,
        practitionerFetchingState: {
          ...state.practitionerFetchingState,
          isGetPractitionerDetailsFetching: false,
        },
        error: true,
      };
    default: {
      return { ...state };
    }
  }
};

export default PractitionerReducer;
