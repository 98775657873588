import React from 'react';
import PropTypes from 'prop-types';
import Navigation from './Navigation';

const Header = ({ title, headerAction, children, breadcrumbs, timeline, size, buttons, loading, overrideNavActive }) => {
  return (
    <>
      <Navigation  />
    </>
  );
};

export default Header;

Header.propTypes = {
  title: PropTypes.any,
  breadcrumbs: PropTypes.any,
  subnav: PropTypes.bool,
  headerAction: PropTypes.string,
  children: PropTypes.any,
  timeline: PropTypes.element,
  size: PropTypes.string,
  buttons: PropTypes.element,
  loading: PropTypes.bool,
  overrideNavActive: PropTypes.string
};