import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";

function PasswordValidationInfoPopup() {
  return (
    <Tippy
      content={
        <ul>
          <li>Minimum length of the password should be 8 characters. </li>
          <li>Password should contain uppercase and lowercase letters.</li>
          <li>Password should contain letters and numbers.</li>
          <li>Password should contain special characters.</li>
        </ul>
      }
      allowHTML={true}
    >
      <div className="spn-c-p" style={{ display: "inline-block" }}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </div>
    </Tippy>
  );
}

export default PasswordValidationInfoPopup;
