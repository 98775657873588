const PROGRAMME_INITIAL_FETCHING_VALUES = {
  isGetClientProgrammeFetching: false,
  isGetSharedDataForProgrammeFetching: false,
  isSubscribeToProgrammeFetching: false,
  isGetProgrammeDetailsForClientPortalFetching: false,
  isGetTimeIntervalsFetching: false,
  isGetProgrammeBookingDetailsForClientPortalFetching: false,
  isBookSlotFetching: false,
  isRateEmployeeProgrammeFetching: false,
  isGetEmployeeProgrammeDetailsforRatingFetching: false,
};

export const PROGRAMME_INITIAL_VALUES = {
  error: false,
  programmeFetchingState: {
    ...PROGRAMME_INITIAL_FETCHING_VALUES,
  },
};
