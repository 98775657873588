import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import Modal from "react-bootstrap/Modal";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { MainContext } from "../../context/MainContext";
import {
  getProgrammeDetailsForClientPortal,
  getTimeIntervals,
  subscribeToProgramme,
} from "../../context/programmeContext/apiCalls";
import Alert from "../../components/GetZen/Alert";
import GlobalError from "../../components/GetZen/GlobalError";
import {
  getPractitionerDetails,
  getPractitionerListForClientPortalProgramme,
} from "../../context/practitionerContext/apiCalls";
import CurrencyFormat from "react-currency-format";
import momentTimeZone from "moment-timezone";
import Input from "../../components/GetZen/Input";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";

function BookingDetails() {
  const { dispatch, error } = useContext(MainContext);
  const { programmeId } = useParams();
  const [subscriptionData, setSubscriptionData] = useState({
    programmeId: programmeId,
    practitionerId: "",
    subscribedUserId: "",
    noOfSlots: "",
    seletedDate: new Date().toISOString().slice(0, 10),
    startSlotTime: "",
    endSlotTime: "",
    meetingLink: "",
    isActive: true,
    LocalStartSlotDateTime: "",
    LocalTimeZone: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    address: "",
    poNumber: "",
  });
  const [programmeDetails, setProgrammeDetails] = useState({
    name: "",
    shortDescription: "",
    programmeTypeName: "",
    amountOfAttendees: "",
    price: "",
    programmeDeliveryCode: "",
    programmeDeliveryName: "",
    imageUrl: "",
    description: "",
    durationMinutes: "",
    durationFormatToDisplay: "",
    venue: "",
    sessionRequirments: "",
    sessionAgenda: "",
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertDisplay, setAlertDisplay] = useState(false);
  const [globaleError, setGlobalError] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const [practitionerData] = useState({
    programmeId: programmeId,
    selectedDate: moment.utc().format(),
    startTime: "",
    endTime: "",
  });
  const [practitionerList, setPractitionerList] = useState([]);
  const [practitionerPopUpData, setPractitionerPopUpData] = useState({});
  const [selectedTime, setSelectedTime] = useState(null);
  const [active, setActive] = useState();
  const [meetingLinkError, setMeetingLinkError] = useState(false);
  const [decimalHave, setDecimalHave] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [getTimeIntervalsPayload, setGetTimeIntervalsPayload] = useState({
    selectedDate: moment.utc().format(),
    programmeId: programmeId,
    SelectedLocalDateTime: moment.utc().local().format(),
  });
  const [isOnsiteProgramme, setIsOnsiteProgramme] = useState(false);

  setTimeout(() => {
    if (alertDisplay === true) {
      setAlertDisplay(false);
    }
  }, 3000);

  useEffect(() => {
    let isApiSubscribed = true;
    getProgrammeDetailsForClientPortal({ programmeId: programmeId }, dispatch)
      .then((response) => {
        if (isApiSubscribed) {
          setProgrammeDetails({
            name: response.data.name,
            shortDescription: response.data.shortDescription,
            programmeTypeName: response.data.programmeTypeName,
            amountOfAttendees: response.data.amountOfAttendees,
            price: response.data.price,
            programmeDeliveryCode: response.data.programmeDeliveryCode,
            programmeDeliveryName: response.data.programmeDeliveryName,
            imageUrl: response.data.imageUrl,
            description: response.data.description,
            durationMinutes: response.data.durationMinutes,
            durationFormatToDisplay: response.data.durationFormatToDisplay,
            venue: response.data.venue,
            sessionRequirments: response.data.sessionRequirments,
            sessionAgenda: response.data.sessionAgenda,
          });
          setSubscriptionData((g) => ({
            ...g,
            noOfSlots: response.data.amountOfAttendees,
          }));
          setIsOnsiteProgramme(response.data.programmeDeliveryCode === "PD02");
        }
      })
      .catch((err) => {
        setGlobalError((g) => [...g, err.response.data]);
      });

    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [programmeId, dispatch]);

  useEffect(() => {
    let isApiSubscribed = true;

    getPractitionerListForClientPortalProgramme(practitionerData, dispatch)
      .then((response) => {
        if (isApiSubscribed) {
          setPractitionerList(response.data);
        }
      })
      .catch((err) => {
        setGlobalError((g) => [...g, err.response.data]);
      });

    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [practitionerData, dispatch]);

  useEffect(() => {
    let isApiSubscribed = true;
    getTimeIntervals(getTimeIntervalsPayload, dispatch)
      .then((response) => {
        if (isApiSubscribed) {
          setTimeSlot(response.data);
        }
      })
      .catch((err) => {
        setGlobalError((g) => [...g, err.response.data]);
      });

    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [getTimeIntervalsPayload, dispatch]);

  // const events = [{ title: "Event", date: new Date() }];

  useEffect(() => {
    var result =
      programmeDetails.price - Math.floor(programmeDetails.price) !== 0;

    setDecimalHave(result);
  }, [programmeDetails.price]);

  const [show, setShow] = useState(false);
  const [selectedDate, setselectedDate] = useState(
    moment(new Date().toISOString().slice(0, 10)).format("Do MMMM YYYY")
  );

  const handleClose = () => {
    setMeetingLinkError(false);
    setShow(false);
  };

  const handleShow = (e) => {
    if (e.isActive) {
      if (selectedTime === null) {
        setAlertMessage("Please select time slot");
        setAlertDisplay(true);
        setAlertType("");
      } else {
        let data = {
          userId: e.userId,
          selectedDate: getTimeIntervalsPayload.selectedDate,
          startSlotTime: selectedTime.startTime,
          endSlotTime: selectedTime.endTime,
        };

        setSubscriptionData((g) => ({
          ...g,
          practitionerId: e.userId,
        }));

        getPractitionerDetails(data, dispatch)
          .then((response) => {
            setPractitionerPopUpData(response.data);
            setShow(true);
          })
          .catch((err) => {
            setGlobalError([err.response.data]);
          });
      }
    }
  };

  const selectDate = (e) => {
    setSubscriptionData((g) => ({ ...g, seletedDate: e.startStr }));
    setselectedDate(moment(e.startStr).format("Do MMMM YYYY"));

    if (moment.utc().local().format("YYYY-MM-DD") === e.startStr) {
      setGetTimeIntervalsPayload((prevdata) => ({
        ...prevdata,
        selectedDate: moment.utc().format(),
        SelectedLocalDateTime: moment.utc().local().format(),
      }));
    } else {
      setGetTimeIntervalsPayload((prevdata) => ({
        ...prevdata,
        selectedDate: e.startStr,
        SelectedLocalDateTime: moment
          .utc(e.startStr + "T00:00:00Z")
          .local()
          .format(),
      }));
    }
  };

  // eslint-disable-next-line
  const timeZone = new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1];

  const subscribe = (data) => {
    const updatedsubscriptionData = {
      ...subscriptionData,
      meetingLink: data.meetingLink,
      contactName: data.contactName,
      contactNumber: data.contactNumber,
      contactEmail: data.contactEmail,
      address: data.address,
      poNumber: data.poNumber,
    };

    subscribeToProgramme(updatedsubscriptionData, dispatch)
      .then(() => {
        setShow(false);
        setAlertMessage("Thank you, you are booked");
        setAlertDisplay(true);
        setAlertType("Success");
      })
      .catch((err) => {
        setGlobalError([err.response.data]);
      });
  };

  const timeSlotClick = (e, idx) => {
    let newarr = [];

    for (let i = 0; i < e.practitioners.length; i++) {
      let p = practitionerList.find(
        (item) => item.userId === e.practitioners[i].userId
      );
      p.isActive = e.practitioners[i].isAvailable;
      newarr.push(p);
    }

    setPractitionerList(newarr);

    if (active === idx) {
      // change active to blank
      setActive();
    } else {
      // change active to current index
      setActive(idx);
    }

    var returned_endDateTime = moment(e.startSlotTime, "HH:mm")
      .add(programmeDetails.durationMinutes, "minutes")
      .format("HH:mm");
    setSelectedTime({
      startTime: e.startSlotTime,
      endTime: returned_endDateTime,
    });

    setSubscriptionData((g) => ({
      ...g,
      startSlotTime: e.startSlotTime,
      endSlotTime: returned_endDateTime,
      LocalStartSlotDateTime: moment
        .utc(
          moment
            .utc(getTimeIntervalsPayload.selectedDate)
            .format("YYYY-MM-DD") +
            "T" +
            e.startSlotTime +
            ":00Z"
        )
        .local()
        .format("YYYY-MM-DD hh:mm a"),
      LocalTimeZone: momentTimeZone.tz.guess(),
    }));
  };

  const bookingInitialvalues = {
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    address: "",
    meetingLink: "",
    poNumber: "",
  };

  const bookingContactInfoValidation = Yup.object().shape({
    contactName: Yup.string().when(() => {
      if (isOnsiteProgramme)
        return Yup.string().required("Contact Name is required");
    }),
    contactNumber: Yup.string().when(() => {
      if (isOnsiteProgramme)
        return Yup.string().required("Contact Number is required");
    }),
    contactEmail: Yup.string().when(() => {
      if (isOnsiteProgramme)
        return Yup.string().required("Contact Email is required");
    }),
    address: Yup.string().when(() => {
      if (isOnsiteProgramme)
        return Yup.string().required("Address is required");
    }),
    meetingLink: Yup.string().when(() => {
      if (!isOnsiteProgramme)
        return Yup.string().required("Meeting Link is required");
    }),
    poNumber: Yup.string().nullable(),
  });

  return (
    <>
      <Header title="Booking" subnav={false} headerAction="Add Booking Item" />
      <div className="container">
        <div className="row mt-3">
          <div className="col-12 col-sm-12 col-md-5 ">
            {/* <div className="pt-3">
              <button className="active btn btn-sm btn-getzenprimary rounded-pill text-capitalize">
                {state.sessionTypeName}
              </button>
            </div> */}
            <h1 className="py-3 text-capitalize mt-3">
              {programmeDetails.name}
            </h1>
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetails.shortDescription,
              }}
            ></div>
            {programmeDetails.programmeTypeName ? (
              <div className="border-1 border-bottom py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Programme type:</div>
                  <div>{programmeDetails.programmeTypeName}</div>
                </div>
              </div>
            ) : null}
            {programmeDetails.amountOfAttendees ? (
              <div className="border-1 border-bottom py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Attendees:</div>
                  <div>
                    {programmeDetails.amountOfAttendees}{" "}
                    {programmeDetails.amountOfAttendees === "1"
                      ? "Person"
                      : "People"}
                  </div>
                </div>
              </div>
            ) : null}
            {/* <div className="border-1 border-bottom py-3">
              <div className="d-flex justify-content-between">
                <div className="fw-bold">Practitioner:</div>
                <div> Lauren Raby</div>
              </div>
            </div> */}
            {programmeDetails.price > 0 ? (
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">Price (excl.VAT):</div>
                  <div>
                    {/* <h1>£{programmeDetails.price}</h1> */}
                    <h1>
                      <CurrencyFormat
                        value={programmeDetails.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"£"}
                        decimalScale={2}
                        fixedDecimalScale={decimalHave ? true : false}
                      />
                    </h1>
                  </div>
                </div>
              </div>
            ) : programmeDetails.price === 0 ? (
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  <div className="fw-bold">This is a free programme </div>
                </div>
              </div>
            ) : null}
          </div>
          {programmeDetails.imageUrl ? (
            <div className="col-12 col-sm-12 col-md-7 d-flex align-items-center justify-content-center mt-3">
              <div className="proDetails">
                <img
                  src={programmeDetails.imageUrl}
                  alt="img"
                  className="img"
                />
              </div>
            </div>
          ) : null}
          <div className="col-12 mt-3">
            <hr />
          </div>
          <div className="col-12 col-sm-12 col-md-5 ">
            <h3>About the session</h3>
          </div>
          <div className="col-12  col-sm-12 col-md-7 ">
            <div className="fa-1x fw-bold mb-3">Description</div>
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetails.description,
              }}
            ></div>
            <div className="mt-5">
              <p>
                <strong>Programme type: </strong>
                {programmeDetails.programmeTypeName}
              </p>
              {programmeDetails.durationFormatToDisplay ? (
                <p>
                  <strong>Duration: </strong>
                  {programmeDetails.durationFormatToDisplay}
                </p>
              ) : null}
              {programmeDetails.programmeDeliveryName ? (
                <p>
                  <strong>Location:</strong>{" "}
                  {programmeDetails.programmeDeliveryName}
                </p>
              ) : null}
              {programmeDetails.sessionRequirments ? (
                <div>
                  <strong>Requirements: </strong>
                  <div
                    className="d-inline-block"
                    dangerouslySetInnerHTML={{
                      __html: programmeDetails.sessionRequirments,
                    }}
                  ></div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-12">
            <hr />
          </div>
          <div className="col-12 col-sm-12 col-md-5 ">
            <h3>Agenda</h3>
          </div>
          <div className="col-12  col-sm-12 col-md-7 ">
            <div
              dangerouslySetInnerHTML={{
                __html: programmeDetails.sessionAgenda,
              }}
            ></div>
          </div>
          <div className="col-12">
            <hr />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <h3>Programme calendar </h3>
            <div className="fc fc-ltr fc-unthemed mb-5">
              <FullCalendar
                initialView="dayGridMonth"
                plugins={[interactionPlugin, dayGridPlugin]}
                // events={events}
                dayMaxEventRows={true}
                selectable={true}
                select={(e) => selectDate(e)}
                eventClick={function (info) {
                  info.jsEvent.preventDefault(); // don't let the browser navigate

                  if (info.event.url) {
                    window.open(info.event.url);
                  }
                }}
                selectAllow={function (selectInfo) {
                  let today = new Date().toISOString().slice(0, 10);
                  if (today <= selectInfo.startStr) {
                    return true;
                  }
                }}
                dateClick={function (date) {
                  setActive("");
                  setSelectedTime(null);

                  setPractitionerList((g) =>
                    g.map((item) => ({ ...item, isActive: false }))
                  );
                  if (
                    moment(new Date().toISOString().slice(0, 10)).format(
                      "YYYY-MM-DD"
                    ) !== date.dateStr
                  ) {
                    const futureDates = document.querySelectorAll(
                      ".fc-daygrid-day.fc-day-future"
                    );
                    futureDates.forEach((item) => {
                      item.style.backgroundColor = "white";
                    });
                    date.dayEl.style.backgroundColor = "#f5f4f4";
                    const pastDates = document.querySelectorAll(
                      ".fc-daygrid-day.fc-day-past"
                    );
                    pastDates.forEach((item) => {
                      item.style.backgroundColor = "white";
                    });
                  } else {
                    const futureDates = document.querySelectorAll(
                      ".fc-daygrid-day.fc-day-future"
                    );
                    futureDates.forEach((item) => {
                      item.style.backgroundColor = "white";
                    });
                  }
                }}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 ps-5">
            <div className="row mt-1 mt-md-5">
              <div className="col-12 d-flex flex-wrap">
                <h3 className="col-12">Time slots (Please select time slot)</h3>
                <p className="mb-4 fw-bold text-secondary col-12">
                  {selectedDate} {timeZone}
                </p>
                {timeSlot.map((t, index) => (
                  <button
                    key={index}
                    className={
                      (active === index ? "activeTimeSlot" : "") +
                      "btn btn-sm btn-outline-getzenprimary rounded-0 border border-1 btn-time m-1 col-bl-5 time-slot-btn"
                    }
                    onClick={() => timeSlotClick(t, index)}
                    disabled={!t.isAvailable}
                  >
                    {moment
                      .utc(
                        moment
                          .utc(getTimeIntervalsPayload.selectedDate)
                          .format("yyyy-MM-DD") +
                          "T" +
                          t.startSlotTime +
                          ":00Z"
                      )
                      .local()
                      .format("hh:mm a")}
                  </button>
                ))}
              </div>
              <div className="col-12">
                <hr />
                <h3>Practitioners (Please select practitioner)</h3>
              </div>
              <div className="col-12  mb-3">
                {practitionerList.map((item, index) => (
                  <div key={item.practitionerId}>
                    <div className="col-12" onClick={() => handleShow(item)}>
                      <span
                        className={
                          (!item.isActive ? "not-active " : "") +
                          "d-flex align-items-center practitioners text-decoration-none spn-c-p"
                        }
                      >
                        <div className="p-1 text-center">
                          <img
                            src={item.profileImageUrl}
                            className="rounded-circle border border-2 profile-border"
                            alt=" "
                          />
                        </div>
                        <div>
                          <p className="mt-2 mb-0 fw-bold">
                            {item.firstName} {item.surname}
                          </p>
                          <div
                            className="small mb-0"
                            dangerouslySetInnerHTML={{
                              __html: item.shortDescription,
                            }}
                          ></div>
                        </div>
                      </span>
                    </div>
                    {practitionerList.length !== index + 1 ? (
                      <hr className="my-2" />
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="h5 practitioner-model">
            Practitioner
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 ">
              <div className="d-flex flex-row mb-3">
                <div>
                  <img
                    src={practitionerPopUpData.profileImageUrl}
                    className="rounded-circle border border-2 profile-border"
                    alt=""
                  />
                </div>
                <div className="ps-3">
                  <p className="mt-2 mb-1 fw-bold">
                    {practitionerPopUpData.firstName}{" "}
                    {practitionerPopUpData.surname}
                  </p>
                  <div
                    className="small fst-italic"
                    dangerouslySetInnerHTML={{
                      __html: practitionerPopUpData.shortDescription,
                    }}
                  ></div>
                </div>
              </div>
              <div
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: practitionerPopUpData.description,
                }}
              ></div>
              {
                <div className="col me-3 mb-4">
                  <Formik
                    initialValues={bookingInitialvalues}
                    onSubmit={subscribe}
                    validationSchema={bookingContactInfoValidation}
                    enableReinitialize
                  >
                    {(props) => (
                      <form onSubmit={props.handleSubmit}>
                        <div>
                          <div>
                            <label
                              htmlFor="poNumber"
                              className="form-label fw-medium-bold"
                            >
                              Please enter the PO number if required by your
                              organisation prior to booking
                            </label>
                            <div className="form-group row mt-1 mb-3">
                              <div className="col-12">
                                <Input
                                  name="poNumber"
                                  type="text"
                                  className="form-control form-control-sm d-inline-block"
                                  id="poNumber"
                                  placeholder="PO Number"
                                  value={props.values.poNumber}
                                  onChange={props.handleChange}
                                />
                                <ErrorMessage
                                  name="poNumber"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          </div>
                          <label
                            htmlFor="link"
                            className="form-label fw-medium-bold mt-2"
                          >
                            {isOnsiteProgramme ? (
                              "Please add contact information"
                            ) : (
                              <div>
                                Please add a meeting link
                                <span style={{ color: "red" }}>*</span>{" "}
                              </div>
                            )}
                          </label>
                          {isOnsiteProgramme ? (
                            <>
                              <div className="form-group row">
                                <label
                                  htmlFor="contactName"
                                  className="col-12 form-label fw-medium-bold"
                                >
                                  Contact Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-12">
                                  <Input
                                    name="contactName"
                                    className="form-control form-control-sm"
                                    type="text"
                                    id="contactName"
                                    value={props.values.contactName}
                                    onChange={props.handleChange}
                                  />
                                  <ErrorMessage
                                    name="contactName"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="form-group row mt-1">
                                <label
                                  htmlFor="contactNumber"
                                  className="col-12 form-label fw-medium-bold"
                                >
                                  Contact Number{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-12">
                                  <Input
                                    name="contactNumber"
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="contactNumber"
                                    value={props.values.contactNumber}
                                    onChange={props.handleChange}
                                  />
                                  <ErrorMessage
                                    name="contactNumber"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="form-group row mt-1">
                                <label
                                  htmlFor="contactEmail"
                                  className="col-12 form-label fw-medium-bold"
                                >
                                  Contact Email{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-12">
                                  <Input
                                    name="contactEmail"
                                    type="email"
                                    className="form-control form-control-sm"
                                    id="contactEmail"
                                    value={props.values.contactEmail}
                                    onChange={props.handleChange}
                                  />
                                  <ErrorMessage
                                    name="contactEmail"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                              <div className="form-group row mt-1">
                                <label
                                  htmlFor="address"
                                  className="col-12 form-label fw-medium-bold"
                                >
                                  Address{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-12">
                                  <textarea
                                    name="address"
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    value={props.values.address}
                                    onChange={props.handleChange}
                                  />
                                  <ErrorMessage
                                    name="address"
                                    component="span"
                                    className="error"
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="form-group row mt-1">
                              <div className="col-12">
                                <textarea
                                  name="meetingLink"
                                  type="text"
                                  className="form-control form-control-sm d-inline-block"
                                  id="meetingLink"
                                  placeholder="Meeting Link"
                                  value={props.values.meetingLink}
                                  onChange={props.handleChange}
                                />
                                <ErrorMessage
                                  name="meetingLink"
                                  component="span"
                                  className="error"
                                />
                              </div>
                            </div>
                          )}
                          <div className="float-end d-flex align-items-end mt-3">
                            <button
                              type="submit"
                              className="btn btn-outline-getzenprimary border border-2 rounded-0"
                              disabled={
                                practitionerPopUpData.isAvailable ? false : true
                              }
                            >
                              Book now
                            </button>
                            <button
                              type="button"
                              className="btn btn-outline border border-2 rounded-0 ms-1"
                              data-bs-dismiss="modal"
                              onClick={handleClose}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  {meetingLinkError ? (
                    <span className="error">Please fill the meeting link</span>
                  ) : null}
                </div>
              }
              <div className="w-100 d-flex align-items-center">
                <div className="col">
                  {!practitionerPopUpData.isAvailable
                    ? "Not available for selected time"
                    : null}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Alert
        message={alertMessage}
        display={alertDisplay}
        statusType={alertType}
      />
      {error ? <GlobalError message={globaleError} /> : null}
    </>
  );
}

export default BookingDetails;
