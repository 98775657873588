import React, { useState, useEffect, useRef, useContext } from "react";
import { matchPath } from "react-router-dom";
import CustomSelect from "../../components/GetZen/Select/select/Select";
import { MainContext } from "../../context/MainContext";

function SortSideBar({ display, closeButton, getData, clickedSort }) {
  const [show, setShow] = useState(false);
  const dpRef = useRef([]);
  const [filterValue, setFilterValue] = useState({});
  const [inputValues, setInputValues] = useState({
    sort: null,
  });
  const [newFilterValue, setNewFilterValue] = useState({});
  const [updatedInputvalues, setUpdatedInputValues] = useState({});
  const { selectedFilter } = useContext(MainContext);

  let prevUrl = sessionStorage.getItem("prevPath");
  let goD = sessionStorage.getItem("goD");

  useEffect(() => {
    setShow(display);

    if (prevUrl) {
      if (matchPath("/our-programme/:programmeId", prevUrl)) {
        setInputValues((g) => ({
          ...g,
          sort: selectedFilter.sort,
        }));
      } else if (
        !matchPath("/our-programme/:programmeId", prevUrl) &&
        goD === "true"
      ) {
        setInputValues((g) => ({
          ...g,
          sort: null,
        }));
        sessionStorage.setItem("goD", false);
      }
    }
  }, [display, prevUrl, selectedFilter, goD]);

  function closeFilter() {
    setShow(false);
    closeButton(false);

    if (Object.keys(newFilterValue).length === 0) {
      dpRef.current.map((item) => (item !== null ? item.onClear() : null));
    }

    setInputValues({ ...updatedInputvalues });
  }

  function applyFilter() {
    sessionStorage.setItem("prevPath", "/");
    setShow(false);
    closeButton(false);
    Object.keys(filterValue).forEach((key) => {
      if (filterValue[key] === "") {
        delete filterValue[key];
      }
    });

    getData(inputValues);
    clickedSort();

    setNewFilterValue({ ...filterValue });
    setUpdatedInputValues({ ...inputValues });
  }

  const sort = [
    { label: "Programme Name: A-Z", value: "ASC" },
    { label: "Programme Name: Z-A", value: "DESC" },
    { label: "Upcoming", value: "UPCOMING" },
    { label: "Newly added", value: "NEWPROG" },
    { label: "Price: Low to High", value: "PRICEASC" },
    { label: "Price: High to Low", value: "PRICEDESC" },
    { label: "Most Popular", value: "BOOKINGS" },
  ];

  const handleChange = (e, v) => {
    setFilterValue((g) => ({ ...g, [e]: v !== null ? v.label : "" }));
    setInputValues((g) => ({ ...g, [e]: v }));
  };

  return (
    <>
      <div
        className={(show ? "shadow" : "") + " sidebar"}
        style={{ right: show ? "0" : "-350px" }}
      >
        <span className="closebtn spn-c-p" onClick={closeFilter}>
          ×
        </span>
        <div className="p-3">
          <h3>Sort by</h3>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label htmlFor="sort" className="form-label f-16 fw-medium-bold">
                Sort options
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="sort"
                options={sort}
                value={inputValues.sort}
                isClearable={true}
                onChange={(e, v) => handleChange(e, v)}
              />
            </div>
          </div>
          <div className="col-12- pt-5 row">
            <div className="col-12 mb-3 d-flex align-content-lg-start justify-content-end d-flex align-items-end">
              <div>
                <button
                  onClick={closeFilter}
                  className="btn btn-sm btn-getzensecondary me-2 text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-getzenprimary"
                  onClick={applyFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SortSideBar;
