import { PROGRAMME } from "../../core/service/api";
import useAxios from "../../core/jwt/useAxios";
import {
  bookSlotFailure,
  bookSlotStart,
  bookSlotSuccess,
  getClientProgrammeFailure,
  getClientProgrammeStart,
  getClientProgrammeSuccess,
  getEmployeeProgrammeDetailsforRatingFailure,
  getEmployeeProgrammeDetailsforRatingStart,
  getEmployeeProgrammeDetailsforRatingSuccess,
  getProgrammeBookingDetailsForClientPortalFailure,
  getProgrammeBookingDetailsForClientPortalStart,
  getProgrammeBookingDetailsForClientPortalSuccess,
  getProgrammeDetailsForClientPortalFailure,
  getProgrammeDetailsForClientPortalStart,
  getProgrammeDetailsForClientPortalSuccess,
  getSharedDataForProgrammeFailure,
  getSharedDataForProgrammeStart,
  getSharedDataForProgrammeSuccess,
  getTimeIntervalsFailure,
  getTimeIntervalsStart,
  getTimeIntervalsSuccess,
  rateEmployeeProgrammeFailure,
  rateEmployeeProgrammeStart,
  rateEmployeeProgrammeSuccess,
  subscribeToProgrammeFailure,
  subscribeToProgrammeStart,
  subscribeToProgrammeSuccess,
} from "./ProgrammeActions";

export const getClientProgramme = async (data, dispatch) => {
  dispatch(getClientProgrammeStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.GET_CLIENT_PROGRAMME, data)
      .then((response) => {
        dispatch(getClientProgrammeSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(getClientProgrammeFailure());
        reject(err);
      });
  });
};

export const getSharedDataForProgramme = async (dispatch) => {
  dispatch(getSharedDataForProgrammeStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .get(PROGRAMME.SHAREDDATA_FOR_PROGRAMMES)
      .then((response) => {
        dispatch(getSharedDataForProgrammeSuccess(response.data));
        resolve(response);
      })
      .catch((err) => {
        dispatch(getSharedDataForProgrammeFailure());
        reject(err);
      });
  });
};

export const subscribeToProgramme = async (data, dispatch) => {
  dispatch(subscribeToProgrammeStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.SUBSCRIBE_TO_PROGRAMME, data)
      .then((response) => {
        dispatch(subscribeToProgrammeSuccess(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(subscribeToProgrammeFailure(error.data));
        reject(error);
      });
  });
};

export const getProgrammeDetailsForClientPortal = async (id, dispatch) => {
  dispatch(getProgrammeDetailsForClientPortalStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.GET_PROGRAMME_DETAILES_FOR_CLIENT_PORTAL, id)
      .then((response) => {
        dispatch(getProgrammeDetailsForClientPortalSuccess(response));
        resolve(response);
      })
      .catch((err) => {
        dispatch(getProgrammeDetailsForClientPortalFailure(err));
        reject(err);
      });
  });
};

export const getTimeIntervals = async (data, dispatch) => {
  dispatch(getTimeIntervalsStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.GET_TIME_INTERVALS, data)
      .then((response) => {
        dispatch(getTimeIntervalsSuccess(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(getTimeIntervalsFailure(error));
        reject(error);
      });
  });
};

export const getProgrammeBookingDetailsForClientPortal = async (
  data,
  dispatch
) => {
  dispatch(getProgrammeBookingDetailsForClientPortalStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL, data)
      .then((response) => {
        dispatch(
          getProgrammeBookingDetailsForClientPortalSuccess(response.data)
        );
        resolve(response);
      })
      .catch((error) => {
        dispatch(getProgrammeBookingDetailsForClientPortalFailure(error));
        reject(error);
      });
  });
};

export const bookSlot = async (data, dispatch) => {
  dispatch(bookSlotStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.BOOK_SLOT, data)
      .then((response) => {
        dispatch(bookSlotSuccess(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(bookSlotFailure(error));
        reject(error);
      });
  });
};

export const rateEmployeeProgramme = async (data, dispatch) => {
  dispatch(rateEmployeeProgrammeStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.RATE_EMPLOYEE_PROGRAMME, data)
      .then((response) => {
        resolve(response);
        dispatch(rateEmployeeProgrammeSuccess(response));
      })
      .catch((err) => {
        reject(err);
        dispatch(rateEmployeeProgrammeFailure(err));
      });
  });
};

export const getEmployeeProgrammeDetailsforRating = async (data, dispatch) => {
  dispatch(getEmployeeProgrammeDetailsforRatingStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(PROGRAMME.GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING, data)
      .then((response) => {
        resolve(response);
        dispatch(getEmployeeProgrammeDetailsforRatingSuccess(response));
      })
      .catch((err) => {
        reject(err);
        dispatch(getEmployeeProgrammeDetailsforRatingFailure(err));
      });
  });
};
