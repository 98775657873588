export const registerEmployeeStart = () => ({
  type: "REGISTER_EMPLOYEE_START",
});

export const registerEmployeeSuccess = () => ({
  type: "REGISTER_EMPLOYEE_SUCCESS",
});

export const registerEmployeeFailure = (error) => ({
  type: "REGISTER_EMPLOYEE_FAILURE",
  payload: error,
});

export const resetPasswordStart = () => ({
  type: "RESET_PASSWORD_START",
});

export const restePasswordSuccess = () => ({
  type: "RESET_PASSWORD_SUCCESS",
});

export const resetPasswordFailure = () => ({
  type: "RESET_PASSWORD_FAILURE",
});

export const getUserProfilePictureStart = () => ({
  type: "GET_USER_PROFILE_PICTURE_START",
});

export const getUserProfilePictureSuccess = () => ({
  type: "GET_USER_PROFILE_PICTURE_SUCCESS",
});

export const getUserProfilePictureFailure = () => ({
  type: "GET_USER_PROFILE_PICTURE_FAILURE",
});

export const adminResetPasswordStart = () => ({
  type: "ADMIN_REST_PASSWORD_START",
});

export const adminResetPasswordSuccess = () => ({
  type: "ADMIN_REST_PASSWORD_SUCCESS",
});

export const adminResetPasswordFailure = () => ({
  type: "ADMIN_RESET_PASSWORD_FAILURE",
});

export const UpdateEmployerAdminDetailsStart = () => ({
  type: "UPDATE_EMPLOYER_ADMIN_DETAILS_START",
});

export const UpdateEmployerAdminDetailsSuccess = () => ({
  type: "UPDATE_EMPLOYER_ADMIN_DETAILS_SUCCESS",
});

export const UpdateEmployerAdminDetailsFailure = () => ({
  type: "UPDATE_EMPLOYER_ADMIN_DETAILS_FAILURE",
});
