const SHARED_DATA_INITIAL_FETCHING_VALUES = {
    isGetSharedDataFetching: false,
  };
  
  export const SHARED_DATA_INITIAL_VALUES = {
    error: false,
    sharedDataFetchingState: {
      ...SHARED_DATA_INITIAL_FETCHING_VALUES,
    },
  };
  