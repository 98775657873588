export const authLoginStart = () => ({
  type: "AUTH_LOGIN_START",
});

export const authLoginSuccess = (data) => ({
  type: "AUTH_LOGIN_SUCCESS",
  payload: data,
});

export const authLoginUserTypeSuccess = (user) => ({
  type: "AUTH_LOGIN_USER_TYPE_SUCCESS",
  payload: user,
});

export const authLoginUserDetailsSuccess = (user) => ({
  type: "AUTH_LOGIN_DETAILS_SUCCESS",
  payload: user,
});

export const authLoginFailure = () => ({
  type: "AUTH_LOGIN_FAILURE",
});

//logout
export const logout = () => ({
  type: "LOGOUT",
});
