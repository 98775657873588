const ProgrammeReducer = (state, action) => {
  switch (action.type) {
    case "GET_CLIENT_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetClientProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_CLIENT_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetClientProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_CLIENT_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetClientProgrammeFetching: false,
        },
        error: true,
      };
    case "GET_SHAREDDATA_FOR_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetSharedDataForProgrammeFetching: true,
        },
        error: false,
      };
    case "GET_SHAREDDATA_FOR_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetSharedDataForProgrammeFetching: false,
        },
        error: false,
      };
    case "GET_SHAREDDATA_FOR_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetSharedDataForProgrammeFetching: false,
        },
        error: true,
      };
    case "SUBSCRIBE_TO_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isSubscribeToProgrammeFetching: true,
        },
        error: false,
      };
    case "SUBSCRIBE_TO_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isSubscribeToProgrammeFetching: false,
        },
        error: false,
      };
    case "SUBSCRIBE_TO_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isSubscribeToProgrammeFetching: false,
        },
        error: true,
      };
    case "GET_PROGRAMME_DETAILS_FOR_CLIENT_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetProgrammeDetailsForClientPortalFetching: true,
        },
        error: false,
      };
    case "GET_PROGRAMME_DETAILS_FOR_CLIENT_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetProgrammeDetailsForClientPortalFetching: false,
        },
        error: false,
      };
    case "GET_PROGRAMME_DETAILS_FOR_CLIENT_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetProgrammeDetailsForClientPortalFetching: false,
        },
        error: true,
      };
    case "GET_TIME_INTERVALS_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetTimeIntervalsFetching: true,
        },
        error: false,
      };
    case "GET_TIME_INTERVALS_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetTimeIntervalsFetching: false,
        },
        error: false,
      };
    case "GET_TIME_INTERVALS_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetTimeIntervalsFetching: false,
        },
        error: true,
      };
    case "GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetProgrammeBookingDetailsForClientPortalFetching: true,
        },
        error: false,
      };
    case "GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetProgrammeBookingDetailsForClientPortalFetching: false,
        },
        error: false,
      };
    case "GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetProgrammeBookingDetailsForClientPortalFetching: false,
        },
        error: true,
      };
    case "BOOK_SLOT_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isBookSlotFetching: true,
        },
        error: false,
      };
    case "BOOK_SLOT_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isBookSlotFetching: false,
        },
        error: false,
      };
    case "BOOK_SLOT_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isBookSlotFetching: false,
        },
        error: true,
      };
    case "RATE_EMPLOYEE_PROGRAMME_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isRateEmployeeProgrammeFetching: true,
        },
        error: false,
      };
    case "RATE_EMPLOYEE_PROGRAMME_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isRateEmployeeProgrammeFetching: false,
        },
        error: false,
      };
    case "RATE_EMPLOYEE_PROGRAMME_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isRateEmployeeProgrammeFetching: false,
        },
        error: true,
      };
    case "GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING_START":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetEmployeeProgrammeDetailsforRatingFetching: true,
        },
        error: false,
      };
    case "GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING_SUCCESS":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetEmployeeProgrammeDetailsforRatingFetching: false,
        },
        error: false,
      };
    case "GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING_FAILURE":
      return {
        ...state,
        programmeFetchingState: {
          ...state.programmeFetchingState,
          isGetEmployeeProgrammeDetailsforRatingFetching: false,
        },
        error: true,
      };
    default: {
      return { ...state };
    }
  }
};

export default ProgrammeReducer;
