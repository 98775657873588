import "./css/main.css";
import "./scss/main.scss";
import "antd/lib/date-picker/style/css";
import "tippy.js/dist/tippy.css";

import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
// import { Home } from "./views";
import CustomLogin from "./views/login";
import Register from "./views/register/Register";
import PasswordChange from "./views/register/PasswordChange";
import Booking from "./views/booking";
import BookingDetails from "./views/booking/BookingDetails";
import MyBooking from "./views/mybooking";
import LoginProtectRoute from "./helpers/LoginProtectRoute";
import ProtectRoute from "./helpers/ProtectRoute";
import { MainContext } from "./context/MainContext";
import { useContext } from "react";
import BookingDetailsTwo from "./views/booking/BookingDetailsTwo";
import GlobalLoading from "./components/GetZen/GlobalLoader";
import AdminProtectRoute from "./helpers/AdminProtectRouter";
import EmployeeProtectRoute from "./helpers/EmployeeProtectRoute";
import Rate from "./views/rate/Rate";

export default function App() {
  const { user, userData, isLoading } = useContext(MainContext);

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* <Route
            path="/"
            element={
              <ProtectRoute user={user}>
                <Home />
              </ProtectRoute>
            }
          /> */}
          <Route
            exact
            path="/login"
            element={
              <LoginProtectRoute user={user} roleType={userData}>
                <CustomLogin />
              </LoginProtectRoute>
            }
          />
          {/* <Route
            path="/dashboard"
            element={
              <ProtectRoute user={user}>
                <Home />
              </ProtectRoute>
            }
          /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/password-rest" element={<PasswordChange />} />
          <Route
            path="/our-programme"
            element={
              <EmployeeProtectRoute user={user} roleType={userData}>
                <Booking />
              </EmployeeProtectRoute>
            }
          />
          <Route
            path="/our-programme/:programmeId"
            element={
              <EmployeeProtectRoute user={user} roleType={userData}>
                <BookingDetails />
              </EmployeeProtectRoute>
            }
          />
          <Route
            path="/book-programme/:programmeId"
            element={
              <AdminProtectRoute user={user} roleType={userData}>
                <BookingDetailsTwo />
              </AdminProtectRoute>
            }
          />
          <Route
            path="/mybooking"
            element={
              <ProtectRoute user={user} roleType={userData}>
                <MyBooking />
              </ProtectRoute>
            }
          />
          <Route path="/rateprogramme" element={<Rate />} />
          <Route path="*" element={<Navigate to="/our-programme" />} />
        </Routes>
        {isLoading ? <GlobalLoading /> : null}
      </div>
    </Router>
  );
}
