export const getClientProgrammeStart = () => ({
  type: "GET_CLIENT_PROGRAMME_START",
});

export const getClientProgrammeSuccess = () => ({
  type: "GET_CLIENT_PROGRAMME_SUCCESS",
});

export const getClientProgrammeFailure = () => ({
  type: "GET_CLIENT_PROGRAMME_FAILURE",
});

export const getSharedDataForProgrammeStart = () => ({
  type: "GET_SHAREDDATA_FOR_PROGRAMME_START",
});

export const getSharedDataForProgrammeSuccess = () => ({
  type: "GET_SHAREDDATA_FOR_PROGRAMME_SUCCESS",
});

export const getSharedDataForProgrammeFailure = () => ({
  type: "GET_SHAREDDATA_FOR_PROGRAMME_FAILURE",
});

export const subscribeToProgrammeStart = () => ({
  type: "SUBSCRIBE_TO_PROGRAMME_START",
});

export const subscribeToProgrammeSuccess = () => ({
  type: "SUBSCRIBE_TO_PROGRAMME_SUCCESS",
});

export const subscribeToProgrammeFailure = () => ({
  type: "SUBSCRIBE_TO_PROGRAMME_FAILURE",
});

export const getProgrammeDetailsForClientPortalStart = () => ({
  type: "GET_PROGRAMME_DETAILS_FOR_CLIENT_PROGRAMME_START",
});

export const getProgrammeDetailsForClientPortalSuccess = () => ({
  type: "GET_PROGRAMME_DETAILS_FOR_CLIENT_PROGRAMME_SUCCESS",
});

export const getProgrammeDetailsForClientPortalFailure = () => ({
  type: "GET_PROGRAMME_DETAILS_FOR_CLIENT_PROGRAMME_FAILURE",
});

export const getTimeIntervalsStart = () => ({
  type: "GET_TIME_INTERVALS_START",
});

export const getTimeIntervalsSuccess = () => ({
  type: "GET_TIME_INTERVALS_SUCCESS",
});

export const getTimeIntervalsFailure = () => ({
  type: "GET_TIME_INTERVALS_FAILURE",
});

export const getProgrammeBookingDetailsForClientPortalStart = () => ({
  type: "GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL_START",
});

export const getProgrammeBookingDetailsForClientPortalSuccess = () => ({
  type: "GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL_SUCCESS",
});

export const getProgrammeBookingDetailsForClientPortalFailure = () => ({
  type: "GET_PROGRAMME_BOOKING_DETAILS_FOR_CLIENT_PORTAL_FAILURE",
});

export const bookSlotStart = () => ({
  type: "BOOK_SLOT_START",
});

export const bookSlotSuccess = () => ({
  type: "BOOK_SLOT_SUCCESS",
});

export const bookSlotFailure = () => ({
  type: "BOOK_SLOT_FAILURE",
});

export const rateEmployeeProgrammeStart = () => ({
  type: "RATE_EMPLOYEE_PROGRAMME_START",
});

export const rateEmployeeProgrammeSuccess = () => ({
  type: "RATE_EMPLOYEE_PROGRAMME_SUCCESS",
});

export const rateEmployeeProgrammeFailure = () => ({
  type: "RATE_EMPLOYEE_PROGRAMME_FAILURE",
});

export const getEmployeeProgrammeDetailsforRatingStart = () => ({
  type: "GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING_START",
});

export const getEmployeeProgrammeDetailsforRatingSuccess = () => ({
  type: "GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING_SUCCESS",
});

export const getEmployeeProgrammeDetailsforRatingFailure = () => ({
  type: "GET_EMPLOYEE_PROGRAMME_DETAILS_FOR_RATING_FAILURE",
});
