import React, { useState, useEffect, useRef, useContext } from "react";
import { matchPath } from "react-router-dom";
import CustomSelect from "../../components/GetZen/Select/select/Select";
import { MainContext } from "../../context/MainContext";
import { getSharedDataForProgramme } from "../../context/programmeContext/apiCalls";
import { MapFunc } from "../../helpers/HelperFun";

function FilterSideBar({ display, closeButton, getData, buttonClicked }) {
  const [show, setShow] = useState(false);
  const dpRef = useRef([]);
  const [filterValue, setFilterValue] = useState({});
  const [newFilterValue, setNewFilterValue] = useState({});
  const [inputValues, setInputValues] = useState({
    events: null,
    type: null,
    approach: null,
    challenges: null,
    themes: null,
    delivery: null,
  });
  const [updatedInputvalues, setUpdatedInputValues] = useState({});
  const { dispatch, selectedFilter, setSelectedProgrammeCount } =
    useContext(MainContext);
  const [filterList, setFilterList] = useState({
    CalendarEvents: [],
    PresentingChallenges: [],
    ProgrammeApproachs: [],
    ProgrammeDelivery: [],
    ProgrammeTypes: [],
    WorkplaceThemes: [],
  });

  const [filterCount, setFilterCount] = useState(0);

  let prevUrl = sessionStorage.getItem("prevPath");
  let goD = sessionStorage.getItem("goD");

  useEffect(() => {
    setUpdatedInputValues((g) => ({
      ...g,
      events: selectedFilter.events ? selectedFilter.events : null,
      type: selectedFilter.type ? selectedFilter.type : null,
      approach: selectedFilter.approach ? selectedFilter.approach : null,
      challenges: selectedFilter.challenges ? selectedFilter.challenges : null,
      themes: selectedFilter.themes ? selectedFilter.themes : null,
      delivery: selectedFilter.delivery ? selectedFilter.delivery : null,
    }));
  }, [selectedFilter]);

  useEffect(() => {
    setSelectedProgrammeCount(filterCount);
    if (prevUrl) {
      if (matchPath("/our-programme/:programmeId", prevUrl)) {
        setFilterCount(
          Object.values(inputValues).filter((v) => v != null).length
        );
        setSelectedProgrammeCount(filterCount);
      }
    }
  }, [inputValues, setSelectedProgrammeCount, filterCount, prevUrl]);

  useEffect(() => {
    setShow(display);

    if (prevUrl) {
      if (matchPath("/our-programme/:programmeId", prevUrl)) {
        setInputValues((g) => ({
          ...g,
          events: selectedFilter.events,
          type: selectedFilter.type,
          approach: selectedFilter.approach,
          challenges: selectedFilter.challenges,
          themes: selectedFilter.themes,
          delivery: selectedFilter.delivery,
        }));
      } else if (
        !matchPath("/our-programme/:programmeId", prevUrl) &&
        goD === "true"
      ) {
        setInputValues((g) => ({
          ...g,
          events: null,
          type: null,
          approach: null,
          challenges: null,
          themes: null,
          delivery: null,
        }));
        sessionStorage.setItem("goD", false);
      }
    }
  }, [display, prevUrl, selectedFilter, goD]);

  useEffect(() => {
    let isApiSubscribed = true;

    getSharedDataForProgramme(dispatch).then((response) => {
      if (isApiSubscribed) {
        setFilterList((g) => ({
          ...g,
          CalendarEvents: MapFunc(
            response.data.CalendarEvents,
            "calendarEventName",
            "calendarEventId"
          ),
          PresentingChallenges: MapFunc(
            response.data.PresentingChallenges,
            "presentingChallengeName",
            "presentingChallengeId"
          ),
          ProgrammeApproachs: MapFunc(
            response.data.ProgrammeApproachs,
            "programmeApproachName",
            "programmeApproachId"
          ),
          ProgrammeDelivery: MapFunc(
            response.data.ProgrammeDelivery,
            "programmeDeliveryName",
            "programmeDeliveryId"
          ),
          ProgrammeTypes: MapFunc(
            response.data.ProgrammeTypes,
            "programmeTypeName",
            "programmeTypeId"
          ),
          WorkplaceThemes: MapFunc(
            response.data.WorkplaceThemes,
            "workplaceThemeName",
            "workplaceThemeId"
          ),
        }));
      }
    });

    return () => {
      // cancel the subscription
      isApiSubscribed = false;
    };
  }, [dispatch]);

  function closeFilter() {
    setShow(false);
    closeButton(false);

    if (Object.keys(newFilterValue).length === 0) {
      dpRef.current.map((item) => (item !== null ? item.onClear() : null));
    }

    setInputValues({ ...updatedInputvalues });
  }

  function applyFilter() {
    setFilterCount(Object.values(inputValues).filter((v) => v != null).length);
    buttonClicked(true);
    sessionStorage.setItem("prevPath", "/");
    setShow(false);
    closeButton(false);
    Object.keys(filterValue).forEach((key) => {
      if (filterValue[key] === "") {
        delete filterValue[key];
      }
    });

    getData(inputValues);

    setNewFilterValue({ ...filterValue });
    setUpdatedInputValues({ ...inputValues });
  }

  function reSet() {
    dpRef.current.map((item) => (item !== null ? item.onClear() : null));

    setUpdatedInputValues({ ...inputValues });
  }

  const handleChange = (e, v) => {
    setFilterValue((g) => ({ ...g, [e]: v !== null ? v.label : "" }));
    setInputValues((g) => ({ ...g, [e]: v }));
  };

  return (
    <>
      <div
        className={(show ? "shadow" : "") + " sidebar"}
        style={{ right: show ? "0" : "-350px" }}
      >
        <span className="closebtn spn-c-p" onClick={closeFilter}>
          ×
        </span>
        <div className="p-3">
          <h3>Filter by</h3>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label
                htmlFor="events"
                className="form-label f-16 fw-medium-bold"
              >
                Awareness Days
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="events"
                value={inputValues.events}
                options={filterList.CalendarEvents}
                onChange={(e, v) => handleChange(e, v)}
                isClearable={true}
              />
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label htmlFor="type" className="form-label f-16 fw-medium-bold">
                Programme type
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="type"
                value={inputValues.type}
                options={filterList.ProgrammeTypes}
                onChange={(e, v) => handleChange(e, v)}
                isClearable={true}
              />
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label
                htmlFor="approach"
                className="form-label f-16 fw-medium-bold"
              >
                Programme approach
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="approach"
                value={inputValues.approach}
                options={filterList.ProgrammeApproachs}
                onChange={(e, v) => handleChange(e, v)}
                isClearable={true}
              />
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label
                htmlFor="challenges"
                className="form-label f-16 fw-medium-bold"
              >
                Themes
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="challenges"
                value={inputValues.challenges}
                options={filterList.PresentingChallenges}
                onChange={(e, v) => handleChange(e, v)}
                isClearable={true}
              />
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label
                htmlFor="themes"
                className="form-label f-16 fw-medium-bold"
              >
                Wellness type
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="themes"
                value={inputValues.themes}
                options={filterList.WorkplaceThemes}
                onChange={(e, v) => handleChange(e, v)}
                isClearable={true}
              />
            </div>
          </div>
          <hr />
          <div className="col-12">
            <div className="mb-1">
              <label
                htmlFor="delivery"
                className="form-label f-16 fw-medium-bold"
              >
                Programme delivery
              </label>
              <CustomSelect
                ref={(ref) => dpRef.current.push(ref)}
                name="delivery"
                value={inputValues.delivery}
                options={filterList.ProgrammeDelivery}
                onChange={(e, v) => handleChange(e, v)}
                isClearable={true}
              />
            </div>
          </div>
          <div className="col-12- pt-5 row">
            <div className="col-6 mb-3">
              <button className="btn btn-sm btn-outline me-2" onClick={reSet}>
                Reset filters
              </button>
            </div>
            <div className="col-6 mb-3 d-flex align-content-lg-start justify-content-end d-flex align-items-end">
              <div>
                <button
                  onClick={closeFilter}
                  className="btn btn-sm btn-getzensecondary me-2 text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-sm btn-getzenprimary"
                  onClick={applyFilter}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FilterSideBar;
