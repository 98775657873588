import useAxios from "../../core/jwt/useAxios";
import { AUTH } from "../../core/service/api";
import {
  authLoginFailure,
  authLoginStart,
  authLoginSuccess,
  authLoginUserDetailsSuccess,
  authLoginUserTypeSuccess,
} from "./AuthActions";

export const authLogin = async (data, dispatch) => {
  dispatch(authLoginStart());
  return await new Promise((resolve, reject) => {
    useAxios
      .post(AUTH.LOGIN, data)
      .then((response) => {
        dispatch(authLoginSuccess(response.data));
        dispatch(authLoginUserTypeSuccess(response.data.userType));
        dispatch(
          authLoginUserDetailsSuccess({
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            userType: response.data.userType,
          })
        );
        resolve(response);
      })
      .catch((err) => {
        dispatch(authLoginFailure());
        reject(err);
      });
  });
};
